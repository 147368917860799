import Searcher from './Searcher.js'
import DetailsHandlerDef from "../details/DetailsHandlerDef.js"
import {getWKTParser} from "../util/getWKTParser.js"
import * as reproject from "../util/reproject.js"
import icons from '../resources/icons.js'

/**
 *
 * @class Searches Septima Search Server (S3)
 * @extends module:js/searchers/Searcher
 *
 */

export default class S3Searcher extends Searcher {
  /**
   *
   * @param {Object} options S3Searcher expects these properties:
   * @param {string} options.host The S3 host, e.g. "http://s3.demo.septima.dk"
   * @param {string} options.service The configuration e.g. "/api/v1/organisations/septima/configurations/detailitemdemo"
   *
   */
  constructor(options= {}) {
    super(options)
    
    this.indexedDatasources = null

    this.s3Host = ""
    if (options.host)
      this.s3Host = options.host.replace(/\/$/, "") //remove trailing slash
    
    this.organisation = options.organisation
    this.configuration = options.configuration
    
    if (this.organisation && this.configuration) {
      this.service = `/api/v1/organisations/${this.organisation}/configurations/${this.configuration}`
    } else {
      if (typeof options.service === "undefined" )
        throw "New Septima.Search.S3Searcher(options): Options service or organisation/configuration is missing"
      this.service = options.service
    }
    
    this.endPoint = this.s3Host + this.service
    
    this.showPdfLink = false
    if (options.showPdfLink)
      this.showPdfLink = options.showPdfLink

    this.showLinkToWeb = false
    if (options.showLinkToWeb)
      this.showLinkToWeb = options.showLinkToWeb

    this.authorizationOption = {}
    if (options.authorization) {
      //Must have this format {Bearer: {token: } }
      if (options.authorization.Bearer && options.authorization.Bearer.token)
        this.authorizationOption = {Authorization: options.authorization}
      else
        throw "New Septima.Search.S3Searcher(options): Bearer token not specified correctly ({Bearer: {token: } })"
    }
    
    this.generalFetchOptions = Object.assign(
      {},
      {timeout: 20000},
      this.authorizationOption
    )

    this.sources = null
    this.serverInfo = {
      crsAware: false,
      _updated: false,
      supportsPdf: false,
      supportsMultiFetch: false
    }
    
    this.allowMultiFetch = true
    if (typeof options.allowMultiFetch != 'undefined')
      this.allowMultiFetch = options.allowMultiFetch
    
    this.infoReady = this.getS3Info().catch(error => {
      throw error 
    })
  }

  async getS3Info() {
    if (this.sources === null) {
      this.sources = []
      this.sources = await this.fetch(this.endPoint + '/sources', Object.assign({}, this.generalFetchOptions))
      //The s3 response is controller.getSources() serialized
      for (let source of this.sources) {
        let sourceName = source.source
        for (let type of source.types) 
          this.registerType(sourceName, type)
      }
    }
    if (!this.serverInfo._updated) {
      this.serverInfo._updated = true
      try {
        let serverDescription = await this.fetch(this.s3Host + '/api/v1/description', Object.assign({}, this.generalFetchOptions))
        if (serverDescription.metadata && serverDescription.metadata.version) {
          let majorVer = parseInt(serverDescription.metadata.version.split(".")[0])
          let minorVer = parseInt(serverDescription.metadata.version.split(".")[1])
          if (majorVer >= 1) {
            this.serverInfo.crsAware = true
            if (minorVer >= 3)
              this.serverInfo.supportsPdf = true
            if (minorVer > 7)
              this.serverInfo.supportsMultiFetch = true
          }
        }
        // eslint-disable-next-line no-empty
      } catch{}
    }
  }

  ready() {
    return this.infoReady
  }

  async sq(query) {
    let queryResult = this.createQueryResult()
    if (query.geometry) {
      
      let path = ''
      if (query.hasTarget)
        path = this.getPathFromTarget(query.target)
      let url = this.endPoint + path

      let wktParser = getWKTParser()
      const queryWkt = wktParser.convert(query.geometry)
      let fetchOptions = Object.assign({data: {sq: queryWkt}}, this.generalFetchOptions)

      if (this.serverInfo.crsAware) {
        let epsgCode = reproject.detectProj(query.geometry).epsgCode
        if (epsgCode)
          fetchOptions.data.srs = epsgCode

        if (query.allow_touches)
          fetchOptions.data.allow_touches = true
      }
      
      let data = await this.fetch(url, fetchOptions)
      queryResult = this.createQueryResultFromData(data)
    }
    return queryResult
  }
  
  async fetchData2(query) {
    if (!query.hasTarget && this.serverInfo.supportsMultiFetch && this.allowMultiFetch)
      return this.getFetchPromises(query)
    else
      return[this.myFetchData(query)]
  }
  
  async getFetchPromises(query) {
    let promises = []
    let url = this.endPoint + "/querycount?q="
    switch (query.type) {
      case 'cut':
      case 'no-cut':
      case 'list': {
        url = this.endPoint + "/querycount?q=" + query.queryString
        break
      }
      default:
    }

    let reply = await this.fetch(url, Object.assign({}, this.generalFetchOptions))
    let count = reply.count
    for (let index = 0; index < count; index++) {
      url = this.endPoint + "/byindex?q=" + query.queryString + "&index=" + index
      promises.push( this.fetch(url, Object.assign({}, this.generalFetchOptions)).then(data =>{
        data.query = query
        return this.createQueryResultFromData(data)
      }).catch(error => {
        throw error
      }))
    }
    return promises
  }

  async myFetchData(query) {
    let url
    switch (query.type) {
      case 'collapse': {
        let path = ''
        if (query.hasTarget)
          path = this.getPathFromTarget(query.target)
        url = this.endPoint + path + "?q="
        break
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        let path = ''
        if (query.hasTarget)
          path = this.getPathFromTarget(query.target)
        url = this.endPoint + path + "?q=" + query.queryString
      }
    }
    return this.fetch(url, Object.assign({}, this.generalFetchOptions)).then(data =>{
      data.query = query
      return this.createQueryResultFromData(data)
    }).catch(error => {
      throw error
    })
  }

  getPathFromTarget(target) {
    return "/sources/" + target.source + "/types/" + target.type
  }

  getSourcesAsNewQueries() {
    const queryResult = this.createQueryResult()
    for (let source of this.sources) {
      let sourceName = source.source
      for (let type of source.types) 
        queryResult.addNewQuery(sourceName, type, type, null, "", null, null)
      
    }
    return queryResult
  }

  createQueryResultFromData(data) {
    const queryResult = this.createQueryResult()
    for (let thisItem of data)
      if (thisItem.item === "newquery") {
        let newQuery = queryResult.addNewQuery(thisItem.source, thisItem.type, thisItem.title, thisItem.description, thisItem.newquery, null, null)
        newQuery.image = thisItem.image
      } else if (thisItem.item === "shortresult") {
        let result = queryResult.addResult(thisItem.source, thisItem.type, thisItem.title, thisItem.description, null, null)
        result._s3data = thisItem
        result.image = thisItem.image
        result.id = thisItem.id
        result.isComplete = false
      } else if (thisItem.item === "deepresult") {
        let geometry = thisItem.geometry
        if (geometry && !this.serverInfo.crsAware) {
          geometry.crs  = {
            "type": "name",
            "properties": {
              "name": "epsg:25832"
            }
          }
        }
        let result = queryResult.addResult(thisItem.source, thisItem.type, thisItem.title, thisItem.description, geometry, null)
        result._s3data = thisItem
        result.image = thisItem.image
        if (thisItem.id)
          result.id = thisItem.id
      }
    return queryResult
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      result.isComplete = true
      let s3Result = await this.fetch( this.s3Host + result._s3data.href, Object.assign({}, this.generalFetchOptions))
      result._s3data = s3Result
      result.data = s3Result.data
      result.geometry = s3Result.geometry
      if (s3Result.geometry && !this.serverInfo.crsAware) {
        result.geometry.crs  = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }
      }
      return result
    }
  }

  hasdetailHandlerDefs(result) {
    if (result._s3data.details && result._s3data.details.length > 0) 
      return true
    else 
      return false
  }

  getCustomButtonsForResult(result) {
    let buttons = []
    
    if (this.showLinkToWeb && this.organisation && this.configuration) {
      buttons.push({
        buttonText: "Vis " + result.title + " i OneDoor Web",
        buttonImage: icons.onedoorlink,
        "callBack": (result) => {
          let url = `${this.s3Host}/#/${this.organisation}/${this.configuration}/${result.source}/${result.typeId}/${result.id}`
          window.open(url, "_blank")
        }
      })
    }

    return buttons
  }

  async report(id, type, source, format = "json") {
    let url = this.endPoint + "/sources/" + source + "/types/" + type + "/" + id + "/report"
    let options = {}
    if (format === "pdf") {
      options = { expects: "blob", headers: { 'Content-Type': 'application/pdf' }}
      return this.fetch( url , Object.assign(options, this.generalFetchOptions))
    } else {
      return this.fetch( url , Object.assign(options, this.generalFetchOptions))
    }
  }
  
  async get(id, type, source) {
    const queryResult = this.createQueryResult()
    let url = this.endPoint + "/sources/" + source + "/types/" + type + "/" + id
    let s3Result = await this.fetch( url , Object.assign({}, this.generalFetchOptions))
    if (s3Result.item === "result") {
      let geometry = s3Result.geometry
      if (geometry && !this.serverInfo.crsAware) {
        geometry.crs  = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }
      }
      let result = queryResult.addResult(s3Result.source, s3Result.type, s3Result.title, s3Result.description, geometry, s3Result.data)
      result._s3data = s3Result
      result.image = s3Result.image
      if (s3Result.id) 
        result.id = s3Result.id
      return result
    }
  }
  
  getDetailHandlersForResult(result) {
    const details = result._s3data.details
    let detailHandlerDefs = []
    if (typeof details !== 'undefined') 
      for (let thisDetail of details) 
        if (thisDetail.href) {
          let detailHandlerDef = new DetailsHandlerDef(
            {"buttonText": thisDetail.title,
              "buttonImage": thisDetail.image,
              "handler": async() => {
                let returnItems = []
                let response =  await this.fetch(this.s3Host + thisDetail.href, Object.assign({}, this.generalFetchOptions))
                if (response.message === 'S3Error')
                  throw new Error('S3Error: ' + response.inner)
                for (let detailItem of response) {
                  returnItems.push(this.fixS3DetailItem(detailItem))
                }
                return returnItems  
              },
              "renderHints": thisDetail.renderHints,
              "more": thisDetail.more,
              "id": thisDetail.id}
          )
          detailHandlerDefs.push(detailHandlerDef)
        }
    return detailHandlerDefs
  }
  
  fixS3DetailItem(detailItem) {
    if (detailItem.type === 'result')
      detailItem.result = this.convertResultdetailItemToResult(detailItem)
    else if (detailItem.type === 'link')
      detailItem.link = this.fixLink(detailItem)
    else if (detailItem.type === 'list')
      for(let item of detailItem.items)
        this.fixS3DetailItem(item)
    
    if (detailItem.InfoItems)
      for (let infoItem of detailItem.infoItems)
        this.fixS3DetailItem(infoItem)
    
    return detailItem
  }

  convertResultdetailItemToResult(resultDetailItem) {
    let queryResult = this.createQueryResult()
    let s3Result = resultDetailItem.result
    let result = queryResult.addResult(s3Result.source, s3Result.type, s3Result.title, s3Result.description, null, s3Result.data)
    if (s3Result.geometry && s3Result.geometry !== "") {
      result.geometry = s3Result.geometry
      if (!this.serverInfo.crsAware) {
        result.geometry.crs  = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }
      }
    }
    result._s3data = s3Result
    result.isComplete = false
    result.image = s3Result.image
    result.id = s3Result.id
    return result
  }
  
  fixLink(linkDetailItem) {
    if (this.s3Host) {
      let url = new URL(linkDetailItem.link, this.s3Host)
      return url.toString()
    } else {
      return linkDetailItem.link
    }
  }

}

