/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
/**
 * Viser CVR-registreringer for et husnummer
 * @extends module:js/searchers/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.DataApi.CvrRegistreringerForHusnummer
 *   _options:
 *     searcher:
*        _type: Septima.Search.DataApi.CvrSearcher
*        _options:
*          er_aktiv:
*           - false
*           - true
*          fetcher:
*            _type: Septima.Search.DataApi.Fetcher
*            _options:
*              token: tttttttttttttt

 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 var DataApiFetcher = new Septima.Search.DataApi.Fetcher({
    token: "...."
  })

 var cvrSearcher = new Septima.Search.DataApi.CvrSearcher({
    fetcher: DataApiFetcher,
    goal: "*",
    kommunekode: "0101",
    er_aktiv: [true,false]
  });

 var dawaSearcher = new Septima.Search.DawaSearcher({
    minimumShowCount: 3,
    kommunekode: kommunekode
  });
 
 dawaSearcher.addDetailHandlerDef(new Septima.Search.DataApi.CvrRegistreringerForHusnummer({cvrSearcher: cvrSearcher}))

 * @sspath Septima.Search.DataApi
 * **/

export default class CvrRegistreringerForHusnummer extends DetailsHandlerDef {
  /**
   *
   * @param {Object} options CvrSearcher expects these options:
   * @param {Object} options.searcher Septima.Search.DataApi.CvrSearcher instance
   * @param {Object} options.er_aktiv aktive/inaktive eller begge typer
   * @api
   */
  constructor(options = {}) {
    if (!options.cvrSearcher)
      throw "CvrRegistreringerForHusnummer needs a cvrSearcher"

    let defaultOptions = {
      er_aktiv: [true],
      buttonText: "CVR-registreringer på husnummeret",
      buttonImage: options.cvrSearcher.iconURI,
      targets: [{ source: "Dawa", typeId: "adresse" }]
    }
    let finalOptions = Object.assign(defaultOptions, options)
    super(finalOptions)

    this.searcher = options.cvrSearcher
    this.er_aktiv = finalOptions.er_aktiv

    this.handlerFunction = this.myHandler
  }

  async myHandler(result) {
    let items = []
    let registreringer = await this.searcher.getRegistreringerForHusnummer(result.id, this.er_aktiv)

    if (registreringer.virksomheder) {
      let resultType = this.searcher.getType("cvr", "virksomhed")
      let antal = registreringer.virksomheder.length
      let listOptions = {
        itemType: antal > 0 ? "result" : "labelvalue",
        name: "Cvrnr_liste",
        header: resultType.plural,
        image: resultType.iconURI
      }
      let detailItemsList = new DetailItemsList(listOptions)
      if (antal > 0) {
        for (let virksomhed of registreringer.virksomheder) {
          detailItemsList.append({
            type: "result",
            result: virksomhed
          })
        }
      } else {
        detailItemsList.append({
          type: "labelvalue",
          value: "Ingen " + resultType.plural + " registreret"
        })
      }
      items.push(detailItemsList.asItem())
    }

    if (registreringer.produktionsenheder) {
      let resultType = this.searcher.getType("cvr", "produktionsenhed")
      let antal = registreringer.produktionsenheder.length
      let listOptions = {
        itemType: antal > 0 ? "result" : "labelvalue",
        name: "Pnr_liste",
        header: resultType.plural,
        image: resultType.iconURI
      }
      let detailItemsList = new DetailItemsList(listOptions)
      if (antal > 0) {
        for (let produktionsenhed of registreringer.produktionsenheder) {
          detailItemsList.append({
            type: "result",
            result: produktionsenhed
          })
        }
      } else {
        detailItemsList.append({
          type: "labelvalue",
          value: "Ingen " + resultType.plural + " registreret"
        })
      }
      items.push(detailItemsList.asItem())
    }
    return items
  }

}