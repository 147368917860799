/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import {getWKTParser} from "../../util/getWKTParser.js"
import * as reproject from "../../util/reproject.js"

export default class CowiGadefotoProvider extends DetailsHandlerDef {

  /**
   *
   * @param {Object} options CowiGadefotoProvider expects these properties used to log in to https://cmv.cowi.com/
   * @param {string} [options.username='']  Use this COWI username
   * @param {boolean} [options.password=''] Use this COWI password
   * @param {string} [options.projectname=''] Use this COWI projectname
   * @param {string} [options.projectid=''] use this projectid. Log on to COWI viewer. Open broeser developer console. Find this request and select projectid: https://cmv.cowi.com/Cowi.GIDB.EWebAPI/API/GetAllProjects/ 
   */
  constructor(options) {

    if (typeof options.username === 'undefined')
      throw new Error("CowiGadefotoProvider kaldt uden options.username")
    if (typeof options.password === 'undefined')
      throw new Error("CowiGadefotoProvider kaldt uden options.password")
    if (typeof options.projectname === 'undefined')
      throw new Error("CowiGadefotoProvider kaldt uden options.projectname")
    if (typeof options.projectid === 'undefined')
      throw new Error("CowiGadefotoProvider kaldt uden options.projectid")

    let defaultOptions = {
      buttonText: "Gadefoto"
    }

    super(Object.assign(defaultOptions, options))
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.handler
    this.wktParser = getWKTParser()

    this.username = options.username
    this.password = options.password
    this.projectname = options.projectname
    this.projectid = options.projectid
  }

  isApplicable(result) {
    return (result.typeId === "adresse")
  }

  async handler(result) {
    let pointGeom = this.wktParser.getInteriorPoint(result.geometry)
    let adresselatLon = this.convert2582GeometryTowgs84LatLon(pointGeom)
    let wkt_vejpunkt = `POINT(${result.data.properties.vejpunkt.koordinater[0]} ${result.data.properties.vejpunkt.koordinater[1]})`
    let vejpunkt = this.wktParser.parse(wkt_vejpunkt, 25832)

    let vejpunktlatLon = this.convert2582GeometryTowgs84LatLon(vejpunkt)

    //https://cmv.cowi.com/?u=Varde_Kommune&p=4F3EBB75&project=DDG+Varde+Kommune&srid=25832&maximize=DDG+Latest&x=465920.874&y=6164302.425
    //projectid from; https://cmv.cowi.com/Cowi.GIDB.EWebAPI/API/GetAllProjects/

    let detailItems = []

    /*detailItems.push(
        {
          "type": "link",
          "link": `https://cmv.cowi.com/?u=Varde_Kommune&p=4F3EBB75&project=DDG+Varde+Kommune&srid=25832&maximize=DDG+Latest&&x=${result.geometry.coordinates[0]}&y=${result.geometry.coordinates[1]}`,
          "linkTitle": "Se gadefoto i COWIviewer på adressens  koordinat"
        })
      */
    detailItems.push(
      {
        "type": "link",
        "link": `https://cmv.cowi.com/?u=${this.username}&p=${this.password}&project=${this.projectname}&srid=25832&maximize=DDG+Latest&x=${result.data.properties.vejpunkt.koordinater[0]}&y=${result.data.properties.vejpunkt.koordinater[1]}`,
        "linkTitle": "Se gadefoto hos COWI"// på adressens vejpunkts koordinat"
      })
    /*
    detailItems.push(
      {
        "type": "image",
        "value": `https://gadefotoservice.septima.dk/image_of_address?address=${result.data.properties.adressebetegnelse}&project_id=58293ee6-6512-41c6-b4eb-4c901a79a142&username=Varde_Kommune&password=4F3EBB75`,
        "label": "Billede fra gadefoto via gadefotoservice på adressetekst"
      })
      detailItems.push(
        {
          "type": "image",
          "value": `https://gadefotoservice.septima.dk/image_from_point?from_lon=${adresselatLon.lon}&from_lat=${adresselatLon.lat}&bearing=0&project_id=58293ee6-6512-41c6-b4eb-4c901a79a142&username=Varde_Kommunepassword=4F3EBB75`,
          "label": "Billede fra gadefoto via gadefotoservice på lon/lat"
        })
      */
    detailItems.push(
      {
        "type": "image",
        "value": `https://gadefotoservice.septima.dk/image_from_point_towards_point?from_lon=${vejpunktlatLon.lon}&from_lat=${vejpunktlatLon.lat}&to_lon=${adresselatLon.lon}&to_lat=${adresselatLon.lat}&bearing=0&project_id=${this.projectid}&username=${this.username}&password=${this.password}`,
        "label": ""//Billede fra gadefoto via gadefotoservice fra vejpunkt lon/lat mod adressepunkt lon/lat"
      })


    return detailItems
  }

  convert2582GeometryTowgs84LatLon(geometry) {
    let geoJsonWgs84 = reproject.reproject(geometry, "EPSG:25832", "EPSG:4326")
    return { lon: geoJsonWgs84.coordinates[0], lat: geoJsonWgs84.coordinates[1] }
  }

}