/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
/**
 * Viser søgelink til JO FilArkivet for for Dawa.adresse og Kortforsyningen.matrikelnumre resultater
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.DawaSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.FilarkivLinkForAdresseMatrikelHandler
 *     _options:
 *       kommunenavn: Horsens
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * dawaSearcher.addDetailHandlerDef(new Septima.Search.FilarkivLinkForAdresseMatrikelHandler({kommunenavn: "Horsens"}))
 *
 * @example <caption>ES6:</caption>
 * import FilarkivLinkForAdresseMatrikelHandler from './searchers/detailhandlers/FilarkivLinkForAdresseMatrikelHandler.js'
 * dawaSearcher.addDetailHandlerDef(new FilarkivLinkForAdresseMatrikelHandler({kommunenavn: "Horsens"}))
 * @sspath Septima.Search
 **/
export default class FilarkivLinkForAdresseMatrikelHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {boolean} [options.more=true]
   * @param {string} options.kommunenavn kommunenavn som aftalt med JO
   * @param {string} options.kommunekode tre-cifret kommunekode
   **/
  constructor(options= {}) {
    if (typeof options.kommunenavn === 'undefined')
      throw new Error ("FilarkivLinkForAdresseMatrikelHandler kaldt uden options.kommunenavn")


    let defaultOptions = {
      buttonText: "Søg i Filarkiv",
      buttonImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xNkRpr/UAAAG6SURBVDhPrVQ9awJBEH33oacIUfFEME30YiCmsFAQBP+OpSBaWuQHaO1/ECz8DX6AhW20jJ0oJqfxNHqnl50VL2Ug3oNhZ2Zn380Ob0+wGeACHKJarcYTzWaTr3+hWq3ytdFo8BVExJJE9i+js7yZW0iuRhwic1yBwIxYb4ZD5PP5UCgUMBgMUCqV4PV60Wq1sNvtEAwGIUoSPLKMxWIBNRqFsTUQiURwx/aM7RYS43glIkrW63VEWVEmk0EgEEA6nUa/30ciqSEcDuM5/YKdYSAev6cjSKWeMJ/Psd/v4czodDphvV4jFouh2+2i3W5z0uve+XyG/qkjkdAgyxJMy+Q5VVVxPB4vRIIgwGBfoutI7AqiKHIj/wIBkijha7PBcrmApj3iZFmc6GO1gmkeIVNZpVJBLpfDdDrFcDhEuVyGxQo7nQ6noTGSVLyKgsnkDQ+JJCQ2L+pqNnu/lJAGQqGQXSwWbY/Hw3WRzWbtfD7v6IR1aiuKYvv8fiemWj+L2W1+daTrOnq9HmvRpBDj8Rij0Yj7BJrB4XDANxvqNaZaGjJ1ykHyvkXddJbg2qN17Tfi2ltziQj4ARTUDixARs2vAAAAAElFTkSuQmCC',
      isApplicable: (result) => {
        return ((result.source === "Dawa" && result.typeId === "adresse") || (result.source === "Kortforsyningen" && result.typeId === "matrikelnumre")|| (result.source === "DAF" && result.typeId === "sfe"))
      }
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.kommunenavn = options.kommunenavn
    this.kommunekode = options.kommunekode
  }

  async myHandler(result) {
    let items = []
    if (result.source === "Dawa" && result.typeId === "adresse") {
      let url = `https://${this.kommunenavn}.filarkiv.dk/link/adresse/${this.kommunekode}-${result.data.properties.vejstykke.kode}-${result.data.properties.husnr}`
      items.push(
        { type: 'link',
          icon: this.more ? this.buttonImage : icons.details.link,
          link: url,
          linkTitle: "Søg på adresse i FilArkiv"})
    }
    if (result.source === "Kortforsyningen" && result.typeId === "matrikelnumre") {
      let url = `https://${this.kommunenavn}.filarkiv.dk/link/matrikel/${result.id}`
      items.push(
        { type: 'link',
          icon: this.more ? this.buttonImage : icons.details.link,
          link: url,
          linkTitle: "Søg på matrikelnummer i FilArkiv"})
    }
    if (result.source === "DAF" && result.typeId === "sfe") {
      let url = `https://${this.kommunenavn}.filarkiv.dk/link/bfe/${result.id}`
      items.push(
        { type: 'link',
          icon: this.more ? this.buttonImage : icons.details.link,
          link: url,
          linkTitle: "Søg på bfenummer i FilArkiv"})
    }
    return items
  }

}

/*

Følgende link formater understøttes:
https://{kundeident}.filarkiv.dk/link/ejendom/{kommunekode}-{ejendomsnr}
https://{kundeident}.filarkiv.dk/link/ejendom?kommunekode={kommunekode}&ejendomsnr={ejendomsnr}
https://{kundeident}.filarkiv.dk/link/matrikel/{landsejerlavkode}-{matrikelnr}
https://{kundeident}.filarkiv.dk/link/matrikel?landsejerlavkode={landsejerlavkode}&matrikelnr={matrikelnr}
https://{kundeident}.filarkiv.dk/link/adresse/{kommunekode}-{vejkode}-{husnummer}
https://{kundeident}.filarkiv.dk/link/bfe/{bfenummer}
https://{kundeident}.filarkiv.dk/link/bfe?bfenr={bfenummer}

Hvor {kundeident} er lig med "hvidovre2"

Eksempel på adresse-kald: https://hvidovre2.filarkiv.dk/Link/adresse/167-9588-3
Eksempel på matrikelkald: https://hvidovre2.filarkiv.dk/link/matrikel/12451-24hr
                          https://hvidovre2.filarkiv.dk/link/matrikel/12451-24en
*/
