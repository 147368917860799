/**
 * @module
 */

import QueryBuilder from './QueryBuilder.js'
import DataFetcher from './DataFetcher.js'
import QueryResult from './QueryResult.js'

export default class Controller {
  /**
   * @param {module:js/searchers/Searcher[]} [searchers] Array of searchers
   * @param {Object} [options]
   * @param {string} [options.onError]
   * @param {string} [options.blankBehavior]
   * @param {Object} [options.logger]
   * @param {string} [options.logLevel]
   */
  constructor(searcherRegs, options) {
    let searcherArgs = null
    let optionsArgs = null
    this._searchers = []
    
    if (Array.isArray(searcherRegs)) {
      searcherArgs = searcherRegs
      optionsArgs = options
    } else {
      optionsArgs = searcherRegs
    }

    this._id ="Controller" + '_' + Math.floor(Math.random() * 999999999)
    if (optionsArgs.id) {
      this._id = optionsArgs.id
    }

    if (optionsArgs.logger) {
      let childOptions = {
        module: "Controller"
      }
      if (optionsArgs.logLevel)
        childOptions.level = optionsArgs.logLevel
      this._logger = optionsArgs.logger.child(childOptions)
    }

    //Vars

    this.callbackingDataFetcher = null
    this.onErrorHandlers = []
    this.onAddSearcherHandlers = []
    this.onSearchHandlers = []
    this.onSelectHandlers = []

    this.blankBehavior = "none" //none, search
    this.singleSourceResultsLimit = 100

    //Options
    if (optionsArgs !== null) {
      if (typeof optionsArgs.onError === "function")
        this.addErrorHandler(optionsArgs.onError)
      if (typeof optionsArgs.onSelect === "function")
        this.addOnSelectHandler(optionsArgs.onSelect)
      if (optionsArgs.blankBehavior)
        this.blankBehavior = optionsArgs.blankBehavior
      if (optionsArgs.singleSourceResultsLimit)
        this.singleSourceResultsLimit = optionsArgs.singleSourceResultsLimit
    }

    if (searcherArgs !== null) {
      this.allSearchersProcessed = Promise.resolve('success')
      for (let searcherArg of searcherArgs)
        this.addSearcher(searcherArg)
    }
    if (this._logger)
      this._logger.info('constructed')
    
  }

  getLogger() {
    return this._logger
  }

  set searchers(searchers) {
    this._searchers = []
    this.allSearchersProcessed = Promise.resolve('success')
    for (let searcher of searchers)
      this.addSearcher(searcher)
  }
  
  addSearcher(searcherReg) {
    let searcher = searcherReg
    if (typeof searcherReg.searcher !== 'undefined')
      searcher = searcherReg.searcher
    this._searchers.push(searcher)
    this.allSearchersProcessed = Promise.all([this.allSearchersProcessed, this.thisSearcherProcessed(searcher)])
  }

  addOnSearchHandler(callback) {
    this.onSearchHandlers.push(callback)
  }
  
  callOnSearchHandlers(source, typeId, query) {
    for (let onSearchHandler of this.onSearchHandlers)
      onSearchHandler(source, typeId, query)
  }
  
  addOnSelectHandler(callback) {
    this.onSelectHandlers.push(callback)
  }
  
  async onSelect(result) {
    if (typeof result !== 'undefined')
      if (result.isNewQuery()) {
        this.callSelectHandlers(result)
      } else {
        let completedResult = await result.complete()
        this.callSelectHandlers(completedResult)
      }
  }

  callSelectHandlers(result) {
    if (result.searcher)
      result.searcher._onSelect(result)
    for (let onSelectHandler of this.onSelectHandlers)
      onSelectHandler(result)
  }

  addErrorHandler(callback) {
    this.onErrorHandlers.push(callback)
  }

  async thisSearcherProcessed(searcher) {
    try{
      await searcher.ready()
      this.onAddSearcher()
    }catch(e) {
      this.removeSearcher(searcher)
    }
  }

  removeSearcher(searcher) {
    for (let i=0; i<this._searchers.length; i++) {
      let thisSearcher = this._searchers[i]
      if (thisSearcher.getId() === searcher.getId()) {
        this._searchers.splice(i, 1)
        break
      }
    }
  }

  addOnAddSearcherHandler(callback) {
    this.onAddSearcherHandlers.push(callback)
  }

  onAddSearcher() {
    for (let onAddSearcherHandler of this.onAddSearcherHandlers)
      onAddSearcherHandler()
  }

  getFetchCount(queryString, limit, source, type) {
    let queryBuilder = new QueryBuilder().queryString(queryString).limit(limit).type("list")
    if (source)
      queryBuilder.target({
        source,
        type: type ? type : null
      })
    const query = queryBuilder.build()
    if ((query.queryString.length > 0) || (query.queryString.length === 0 && this.blankBehavior === "search")) {
      let fetchQueries = this.createQueriesFromQueryObject(query)
      return fetchQueries.length
    }
    return 0
  }
  
  fetchDataByIndex(index, queryString, limit, source, type) {
    this.callOnSearchHandlers(source, type, queryString)

    let queryBuilder = new QueryBuilder().queryString(queryString).limit(limit).type("list")
    if (source)
      queryBuilder.target({
        source,
        type: type ? type : null
      })

    const query = queryBuilder.build()
    if ((query.queryString.length > 0) || (query.queryString.length === 0 && this.blankBehavior === "search")) {
      let fetchQueries = this.createQueriesFromQueryObject(query)
      if (index < fetchQueries.length) {
        let dataFetcher = new DataFetcher()
        return dataFetcher.fetch([fetchQueries[index]])
      } else {
        throw new Error("Controller.fetchDataByIndex called with illegal index")
      }
    }
  }

  fetchData(queryString, completeCallback, limit, timeout, source, type) {
    if (completeCallback)
      this.cancelFetches()

    this.callOnSearchHandlers(source, type, queryString)
    
    let queryBuilder = new QueryBuilder().queryString(queryString).limit(limit).type("list")
    if (source)
      queryBuilder.target({
        source,
        type: type ? type : null
      })

    const query = queryBuilder.build()
    if ((query.queryString.length > 0) || (query.queryString.length === 0 && this.blankBehavior === "search")) {
      let fetchQueries = this.createQueriesFromQueryObject(query)
      if (fetchQueries.length > 0) {
        //Create new DataFetcher
        if (completeCallback) {
          this.callbackingDataFetcher = new DataFetcher(completeCallback)
          return this.callbackingDataFetcher.fetch(fetchQueries)
        } else {
          let dataFetcher = new DataFetcher()
          return dataFetcher.fetch(fetchQueries)
        }
      }
    }
    
    const queryResult = new QueryResult(null)
    if (completeCallback)
      completeCallback(queryResult.getAllResults(), true)
    return Promise.resolve(queryResult)
  }
  
  cancelFetches() {
    //Is there an active DataFetcher? then cancel it
    if (this.callbackingDataFetcher !== null) {
      this.callbackingDataFetcher.cancel()
      this.callbackingDataFetcher = null
    }
  }
  
  createQueriesFromQueryObject(callQuery) {
    let fetchQueries = []
    //The two next statements are only used to decide which searchers to call and what limit should be used in the query.
    let searchersToFetchFrom = this.getTargetedSearchersFromQuery(callQuery)
    let typeCount = callQuery.hasTarget ? 1 : this.getSearchTypeCount(searchersToFetchFrom)

    if (searchersToFetchFrom.length === 0) {
      return fetchQueries
    } else if (typeCount === 0 || typeCount === 1) {
      let fetchQueryBuilder = new QueryBuilder(callQuery)
      fetchQueryBuilder.type("list").limit(this.singleSourceResultsLimit)
      let fetchQuery = fetchQueryBuilder.build()
      fetchQuery.searcher = searchersToFetchFrom[0]
      fetchQueries.push(fetchQuery)
      return fetchQueries
    } else {
      let fairShare = Math.ceil(callQuery.limit / typeCount)
      for (let searcher of searchersToFetchFrom) {
        let fetchQueryBuilder = new QueryBuilder(callQuery)
        let minimumShowCount = searcher.minimumShowCount
        if (minimumShowCount === 0) {
          if (callQuery.isBlank)
            fetchQueryBuilder.type("collapse")
          else
            fetchQueryBuilder.type("no-cut").limit(fairShare)
        }else {
          let limit
          if (callQuery.isBlank && !searcher.showMinimumOnBlank) {
            //limit = fairShare
            //limit = 0
            fetchQueryBuilder.type("collapse").limit(1)
          }else{
            limit = Math.max(fairShare, minimumShowCount)
            if (limit < 3)
              fetchQueryBuilder.type("collapse").limit(1)
            else
              fetchQueryBuilder.type("cut").limit(limit)
          }
        }

        let fetchQuery = fetchQueryBuilder.build()
        fetchQuery.searcher = searcher
        fetchQueries.push(fetchQuery)
      }
    }
    return fetchQueries
  }

  getTargetedSearchersFromQuery(query) {
    let targetedSearchers = []
    if (query.hasTarget) {
      for (let thisSearcher of this._searchers) {
        let type = thisSearcher.getType(query.target.source, query.target.type)
        if (type && type.queryBehaviour != 'none' ) {
          if (!(query.isBlank && thisSearcher.blankBehavior === 'none'))
            targetedSearchers.push(thisSearcher)
        }
      }
      return targetedSearchers
    } else {
      //Only return searchers which are searchable
      for (let thisSearcher of this._searchers)
        if (thisSearcher.isSearchable())
          targetedSearchers.push(thisSearcher)
      return targetedSearchers
    }
  }

  getSearcherBySourceType(source, type) {
    for (let thisSearcher of this._searchers)
      if (thisSearcher.hasSource(source) && thisSearcher.hasType(type))
        return thisSearcher
    return null
  }

  sourceTypeIsKnown(source, type) {
    for (let thisSearcher of this._searchers)
      if (thisSearcher.hasSource(source) && thisSearcher.hasType(type))
        return true
    return false
  }

  getSearchTypeCount(searchers) {
    let count = 0
    for (let thisSearcher of searchers) {
      const sources = thisSearcher.getSources()
      if (!sources) {
        let searcherId = thisSearcher.getId() 
        throw new Error("getSources for " + searcherId  + " returned undefined")
      }
      for (let thisSource of sources) {
        for (let type of thisSource.types)
          if (typeof type.queryBehaviour === 'undefined' || type.queryBehaviour === 'search')
            count++
      }
    }
    return count
  }
  onError(searcher, errorThrown) {
    for (let onErrorHandler of this.onErrorHandlers)
      onErrorHandler(searcher, errorThrown)
  }

  async get(source, type, id) {
    await this.allSearchersProcessed
    let searcher = this.getSearcherBySourceType(source, type)
    if (searcher === null) {
      throw (new Error("Controller.get: could not find searcher"))
    } else {
      await searcher.ready()
      let result = await searcher.get(id, type, source)
      if (typeof result === 'undefined' || result === null)
        throw (new Error("Controller.get: could not find object"))

      let completeResult = await result.searcher.completeResult(result)
      if (completeResult === null)
        throw (new Error("Controller.get: completeResult === null"))

      return completeResult
    }
  }

  async getSearchers() {
    let searchers = []
    await this.allSearchersProcessed
    for (let thisSearcher of this._searchers) {
      searchers.push(thisSearcher)
    }
    return searchers
  }
  
  async getSources() {
    await this.allSearchersProcessed
    let sources = []

    //Internal helper function
    let getSource = (sourceName) => {
      for (let i = 0; i < sources.length; i++)
        if (sources[i].source.toLowerCase() === sourceName.toLowerCase())
          return sources[i]
      //Else create the source
      let source = {source: sourceName, types: []}
      sources.push(source)
      return source
    }

    for (let thisSearcher of this._searchers) {
      let thisSearcherSources = thisSearcher.getSources()
      if (!thisSearcherSources) {
        let searcherId = thisSearcher.getId()
        throw new Error("getSources for " + searcherId  + " returned undefined")
      }
      //[{source: "sss", types: [resultType..]}]
      for (let thisSearcherSource of thisSearcherSources) {
        let source = getSource(thisSearcherSource.source)
        source.types = source.types.concat(thisSearcherSource.types)
      }
    }
    return (sources)
  }

  getSearcherFromId(id) {
    for (let i = 0; i < this._searchers.length; i++)
      if (this._searchers[i].getId() == id)
        return this._searchers[i]
    return null
  }

}






