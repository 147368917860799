import pino from 'pino'

export function createLogger(options={}) {
  let defaultOptions = {
    level: "error"
  }
  let finalOptions = Object.assign(defaultOptions, options)
  let logger = pino(finalOptions)
  return logger
}

/*
function fetchJsonp(_url, options = {}) {
  // to avoid param reassign
  let url = _url
  const timeout = options.timeout || defaultOptions.timeout
  const jsonpCallback = options.jsonpCallback || defaultOptions.jsonpCallback

  let timeoutId

  return new Promise((resolve, reject) => {
    const callbackFunction = options.jsonpCallbackFunction || generateCallbackFunction()
    const scriptId = `${jsonpCallback}_${callbackFunction}`

    window[callbackFunction] = (response) => {
      resolve({
        ok: true,
        // keep consistent with fetch API
        json: () => Promise.resolve(response),
      })

      if (timeoutId) clearTimeout(timeoutId)

      removeScript(scriptId)

      clearFunction(callbackFunction)
    }

    // Check if the user set their own params, and if not add a ? to start a list of params
    url += (url.indexOf('?') === -1) ? '?' : '&'

    const jsonpScript = document.createElement('script')
    jsonpScript.setAttribute('src', `${url}${jsonpCallback}=${callbackFunction}`)
    if (options.charset)
      jsonpScript.setAttribute('charset', options.charset)
    jsonpScript.id = scriptId
    document.getElementsByTagName('head')[0].appendChild(jsonpScript)

    timeoutId = setTimeout(() => {
      reject(new Error(`JSONP request to ${_url} timed out`))

      clearFunction(callbackFunction)
      removeScript(scriptId)
      window[callbackFunction] = () => {
        clearFunction(callbackFunction)
      }
    }, timeout)

    // Caught if got 404/500
    jsonpScript.onerror = () => {
      reject(new Error(`JSONP request to ${_url} failed`))

      clearFunction(callbackFunction)
      removeScript(scriptId)
      if (timeoutId) clearTimeout(timeoutId)
    }
  })
} */

export function inherit(C, P) {
  const F = function() {
  }
  F.prototype = P.prototype
  C.prototype = new F()
  let i, l, o
  for (i = 2, l = arguments.length; i < l; i++) {
    o = arguments[i]
    if (typeof o === "function") 
      o = o.prototype
    
    extend(C.prototype, o)
  }
}

export function extend(destination, source) {
  destination = destination || {}
  if (source) {
    for (let property in source) {
      let value = source[property]
      if (value !== undefined) 
        destination[property] = value
      
    }
    if (source.hasOwnProperty && source.hasOwnProperty("toString")) 
      destination.toString = source.toString
    
  }
  return destination
}

export function Class() {
  const len = arguments.length
  const P = arguments[0]
  const F = arguments[len - 1]

  const C = typeof F.initialize == "function" ?
    F.initialize :
    function() {
      P.apply(this, arguments)
    }

  if (len > 1) {
    const newArgs = [C, P].concat(
      Array.prototype.slice.call(arguments).slice(1, len - 1), F)
    inherit.apply(null, newArgs)
  } else {
    C.prototype = F
  }
  return C
}

export function bind(func, object) {
  // create a reference to all arguments past the second one
  const args = Array.prototype.slice.apply(arguments, [2])
  return function() {
    // Push on any additional arguments from the actual function call.
    // These will come after those sent to the bind call.
    const newArgs = args.concat(
      Array.prototype.slice.apply(arguments, [0])
    )
    return func.apply(object, newArgs)
  }
}

export async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 10000 } = options
  try{
    const response = await fetch(resource, {
      ...options,
      signal: AbortSignal.timeout(timeout)
    })
    return response
  } catch(e) {
    if (e.name && e.name === "AbortError")
      throw new Error("fetch timeout for " + resource, e)
    else
      throw(e)
  }
}

export async function fetch2(url, optionsArg = {}, logger) {
  // Try not to destroy caller options
  let options = Object.assign({}, optionsArg)
  if (options.fail) {
    throw("fetch2.options.fail")
  }
  let myLogger
  if (logger) {
    let childOptions = {module: 'utils', function: 'fetch2'}
    myLogger = logger.child(childOptions)
  }
  
  try {
    let throwOnHttpError = false
    if (options.throwOnHttpError)
      throwOnHttpError = true
    let expects = "json"
    if (options && options.expects) {
      expects = options.expects
      delete options.expects
    }
    options.headers = options.headers || {}
    options.method = (options.method ? options.method.toLowerCase() : 'get')
    if (options.data) {
      if (options.method === 'post') {
        if (options.contentType) {
          options.body = options.data
          options.headers['Content-Type'] = options.contentType
        }else {
          options.body = formData(options.data)
          options.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
        }
      } else {
        url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(options.data)
      }
      delete options.data
    }
    options.headers['Accept'] = 'application/json, application/xml, text/play, text/html, application/javascript, */*'
    if (options.Authorization) {
      //https://stackoverflow.com/questions/45535913/setting-authorization-header-in-fetch-api
      if (options.Authorization.Basic && options.Authorization.Basic.username && options.Authorization.Basic.password)
        //Authorization: {Basic: {username: , password: }}
        options.headers["Authorization"] = 'Basic ' + base64Encode(options.Authorization.Basic.username + ":" + options.Authorization.Basic.password)
      //Authorization: {Bearer: {token: }}
      else if (options.Authorization.Bearer && options.Authorization.Bearer.token) {
        options.headers["Authorization"] = 'Bearer ' + options.Authorization.Bearer.token
        options.withCredentials = true
      }
      delete options.Authorization
    }
    options.crossDomain = true
    const start = Date.now()
    let response = await fetchWithTimeout(url, options)
    if (throwOnHttpError && response.status != 200)
      throw ("http status " + response.status)

    if (myLogger) {
      const duration = Date.now() - start
      let logObject = {}
      logObject[options.method] = url
      logObject.duration = duration
      myLogger.debug(logObject)
    }
    if (expects == "xml")
      return await response.text()
    else if (expects == "blob")
      return await response.blob()
    else if (expects == "response")
      return response
    else
      return await response.json()    
  } catch(e) {
    if (myLogger) {
      let logObject = {}
      logObject[options.method] = url
      logObject.exception = e
      myLogger.error(logObject)
    }
    throw e
  }

}

function formData(obj) {
  let formData = []
  Object.keys(obj).forEach((key) => {
    const name = encodeURIComponent(key)
    const value = encodeURIComponent(obj[key])
    formData.push(name + '=' + value)
  })
  return formData.join('&')
}

function queryParams(params) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    //.map(k => k + '=' + params[k])
    .join('&')
}

function base64Encode(str) {
  try {
    return btoa(str)
  } catch(err) {
    return Buffer.from(str).toString('base64')
  }
}
