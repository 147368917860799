/**
 * @module
 */

import Searcher from "../Searcher.js"
import ResultType from "../../ResultType.js"
import {reproject} from "../../util/reproject.js"
import GeoJSON from "ol/format/GeoJSON.js"
import DawaSearcher from "../DawaSearcher.js"
import {WFS} from "ol/format.js"
import {equalTo, intersects as intersectsFilter, or as orFilter} from 'ol/format/filter.js'
import GML3 from "ol/format/GML3.js"
//import {MultiPolygon, Polygon} from "ol/geom.js"
import {MultiPolygon} from "ol/geom.js"

/**
 * Søger bygninger i datafordeleren.bbr
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.Datafordeler.BygningsSearcher
 *   _options:
 *     fetcher:
 *       _type: Septima.Search.Datafordeler.Fetcher
 *       _options:
 *         ...
 * @sspath Septima.Search.Datafordeler
 **/
export default class BygningsSearcher extends Searcher {
  /**
   * @param {Object} options
   * @param {Object} options.fetcher datafordeler.Fetcher
   **/
  constructor(options) {
    options.usesGeoFunctions = true
    super(options)
    this.fetcher = options.fetcher

    this.source = "DAF"

    this.types = { "bbr_bygning": new ResultType({ id: "bbr_bygning", singular: "BBR-bygning", plural: "BBR-bygninger", queryBehaviour: "none" }) }
    this.registerType(this.source, this.types.bbr_bygning)
    this.authParamsDatafordeler = options.authParamsDatafordeler || {
      username: this.fetcher.username,
      password: this.fetcher.password
    }
    this.dawaSearcher = new DawaSearcher({})
  }

  async fetchData(query, caller) {
    caller.fetchSuccess(this.createQueryResult())
  }

  async sqTypes() {
    return [this.types.bbr_bygning]
  }
  
  async sq(query) {
    let queryResult = this.createQueryResult()
    if (query.geometry) {
      let queryGeometry = reproject(query.geometry, null, "EPSG:25832")
      let olGeometry = new GeoJSON().readGeometry(queryGeometry)

      if (olGeometry) {
        let bbruuids = await this.getBbrUuidsFromGeometry(olGeometry)

        let bygninger = await this.gets(bbruuids, 'bbr_bygning')

        for (let bygning of bygninger)
          queryResult.addResult(bygning)
      }
    }
    return queryResult
  }


  santizeXml(xml) {
    xml = xml.replace('http://www.opengis.net/gml/3.2', 'http://www.opengis.net/gml')
    xml = xml.replace('xmlns:wfs="http://www.opengis.net/wfs/2.0"', 'xmlns:wfs="http://www.opengis.net/gml"')
    xml = xml.split('wfs:member').join('wfs:featureMember')
    xml = xml.replace(/(\d),0.0/g, '$1') // Remove z-values from coordinates
    xml = xml.replace(/(\d),(\d)/g, '$1 $2') // Remove , from coordinates
    xml = xml.replace(/<gml:coordinates/g, '<gml:posList srsDimension="2"')
    xml = xml.replace(/<\/gml:coordinates/g, '</gml:posList')
    return xml
  }

  async addFeaturesToQueryResult(queryResult, features) {

    //TBD: lav om til read af list
    await Promise.all(features.map(async (feature) => {
      if (feature.properties.BBRUUID) {
        //TBD: lav om til read af list
        const bbrBygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", {id: feature.properties.BBRUUID}, this.getLogger())
        const resp = bbrBygningerResponse[0]
        if (resp) {
          let title = "BYG: " + resp.byg007Bygningsnummer
          let status = await this.fetcher.findBbrKode("Livscyklus", resp.status)
          let description = resp.byg041BebyggetAreal + " m2" + " ("+ status + ")"
          let type = await this.fetcher.findBbrKode("BygAnvendelse", resp.byg021BygningensAnvendelse)
          if (type)
            description = type + " - " + description
          let geometry
          if (feature.geometry) {
            geometry = feature.geometry
            geometry.crs = {
              "type": "name",
              "properties": {
                "name": "epsg:25832"
              }
            }
          }
          let result = queryResult.addResult(this.source, this.types.bbr_bygning.id, title, description, geometry)
          result.id = resp.id_lokalId
          result.distance = 0
        }
      }
    }))
  }

  removeZ(coordinates) {
    if (coordinates.length > 0 && Array.isArray(coordinates[0])) {
      for (let i = 0; i < coordinates.length; i++)
        this.removeZ(coordinates[i])
    } else {
      coordinates.length = 2
    }
  }

  async get(id, type) {
    let queryResult = this.createQueryResult()
    switch (type) {
      case "bbr_bygning": {
        let bbrBygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", { id: id }, this.getLogger())
        if (bbrBygningerResponse.length > 0) {
          queryResult = await this.bygningToQueryResult(bbrBygningerResponse[0], queryResult)
          let result = queryResult.getResults()[0]
          return result
        }
      }
    }
    return null
  }

  async gets(idArray, type) {
    let queryResult = this.createQueryResult()
    switch (type) {
      case "bbr_bygning": {
        let resultPromises = []
        while (idArray.length > 0) {
          let idsToRead = idArray.splice(0, 150)
          let bbrBygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", { id: idsToRead.join("|") }, this.getLogger())
          for (let bbrBygningResponse of bbrBygningerResponse)
            resultPromises.push(this.bygningToQueryResult(bbrBygningResponse, queryResult))
        }

        await Promise.all(resultPromises)

        const sorter = function (b1, b2) {
          try {
            if (b1.data.byg007Bygningsnummer === b2.data.byg007Bygningsnummer)
              return (0)
            else
              return (b1.data.byg007Bygningsnummer - b2.data.byg007Bygningsnummer)
          } catch (error) {
            return 0
          }
        }
        return queryResult.getResults().sort(sorter)
      }
    }
    return []
  }
  
  async getBygningerForBfeNummer(bfeNummer) {
    let geoms = {}
    let queryResult = this.createQueryResult()
    let grundeResponse = await this.fetcher.read("bbr", "bbr", "grund", {BFEnummer: bfeNummer}, this.getLogger())
    for (let grund of grundeResponse) {
      let grundId = grund.id_lokalId
      let bygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", {grund: grundId, Pagesize: 1000}, this.getLogger())
      for (let bygning of bygningerResponse) {
        geoms[bygning.id_lokalId.toLowerCase()] = bygning.byg404Koordinat ? this.translateWktToGeoJsonObject(bygning.byg404Koordinat, "25832") : null
      }
      await this.fixGeoms(geoms)
      for (let bygning of bygningerResponse) {
        await this.bygningToQueryResult(bygning, queryResult, geoms)
      }
    }
    let results = queryResult.getResults()
    results.sort(this.compareBuildings)

    return queryResult.getResults()
  }
  
  compareBuildings(a, b) {
    try {
      let title_a = a.title.split('-')[1]
      let title_b = b.title.split('-')[1]

      let sortvalue = title_a.localeCompare(title_b)
      
      if (sortvalue == 0) {
        let title_a = a.title.split('-')[0]
        let title_b = b.title.split('-')[0]
        sortvalue = title_a.localeCompare(title_b)
      }

      return sortvalue
    } catch (error) {
      return 0
    }
  }
  
  async bygningToQueryResult(bbrBygningResponse, queryResult, geoms) {
    if (typeof queryResult === 'undefined')
      queryResult = this.createQueryResult()
    let adgangsadressebetegnelse = ""
    try {
      let husnummerId = bbrBygningResponse.husnummer
      let dawaResult = await this.dawaSearcher.get(husnummerId, "adresse")
      adgangsadressebetegnelse = " - " + dawaResult.title
      // eslint-disable-next-line no-empty
    } catch (error) {

    }

    let title = `BYG: ${bbrBygningResponse.byg007Bygningsnummer ? bbrBygningResponse.byg007Bygningsnummer: '(Intet nummer)'}  ${adgangsadressebetegnelse}`
    let type = this.fetcher.findBbrKode("BygAnvendelse", bbrBygningResponse.byg021BygningensAnvendelse)
    let status = await this.fetcher.findBbrKode("Livscyklus", bbrBygningResponse.status)
    if (type)
      title = title + " (" + type + ")"

    let etagecount = bbrBygningResponse.byg054AntalEtager

    let etage = ""
    if (etagecount) {
      if (etagecount > 1)
        etage = " - " + etagecount + " etager"
      else
        etage = " - " + etagecount + " etage"
    }
    let description = ""
    if (bbrBygningResponse.byg038SamletBygningsareal) {
      description = "Samlet areal: " + bbrBygningResponse.byg038SamletBygningsareal + "m2"
    } else {
      if (bbrBygningResponse.byg041BebyggetAreal) {
        description = "Bebygget areal: " + bbrBygningResponse.byg041BebyggetAreal + "m2"
      }
    }
    description +=  etage + " (" + status + ")"


    let geometry = null
    if (geoms) {
      geometry = geoms[bbrBygningResponse.id_lokalId]
    } else {
      if (bbrBygningResponse.byg404Koordinat)
        geometry = await this.getBuildingGeometry(bbrBygningResponse.id_lokalId)
      if (geometry === null && bbrBygningResponse.byg404Koordinat)
        geometry = this.translateWktToGeoJsonObject(bbrBygningResponse.byg404Koordinat, "25832")
    }
      
    let result = queryResult.addResult(this.source, this.types.bbr_bygning.id, title, description, geometry)
    result.id = bbrBygningResponse.id_lokalId
    result.data = bbrBygningResponse
    return queryResult
  }
  
  async fixGeoms(geoms) {
    let filters = []
    let filter
    for (let id_lokalId in geoms) {
      filters.push( equalTo('BBRUUID', id_lokalId, false) )
    }
    if (filters.length > 0) {
      if (filters.length === 1) {
        filter = filters[0]
      } else {
        filter = orFilter(...filters)
      }
      let geojson = await this.getGeoJsonFromFilter(filter)
      for (let feature of geojson.features) {
        let geom = this.getGeomFromFeature(feature)
        geoms[feature.properties.BBRUUID.toLowerCase()] = geom
      }
    }
  }

  async getBuildingGeometry(id_lokalId) {
    try {
      if (typeof id_lokalId === 'undefined')
        return null

      let eqfilter = equalTo('BBRUUID', id_lokalId, false) //skal nok bruges når der er index bå BBRUUID på DFD
      
      let geojson = await this.getGeoJsonFromFilter(eqfilter)

      if (geojson.features.length === 0)
        return null
      
      let resGeom = this.getGeomFromFeature(geojson.features[0])
      return resGeom

    } catch (e) {
      return null
    }
  }
  
  getGeomFromFeature(feature) {
    // use first feature to create multipolygon
    let firstCoordinates = feature.geometry.coordinates

    let mp = new MultiPolygon([firstCoordinates], 'XY')

    /*
    // append the rest 
    geojson.features.slice(1).forEach((f) => {
      let p = new Polygon(f.geometry.coordinates, 'XY')
      mp.appendPolygon(p)
    })
     */

    let resGeom = new GeoJSON({ dataProjection: 'epsg:25832', featureProjection: 'epsg:25832' }).writeGeometryObject(mp)

    resGeom.crs = {
      "type": "name",
      "properties": {
        "name": "epsg:25832"
      }
    }

    return resGeom
    
  }
  
  async getGeoJsonFromFilter(filter) {
    let featureNS = 'http://data.gov.dk/schemas/geodanmark60/2/gml3'
    let featurePrefix = 'gdk60'
    const params = Object.keys(this.authParamsDatafordeler).map(key => key + '=' + this.authParamsDatafordeler[key]).join('&')

    let url = `https://services.datafordeler.dk/GeoDanmarkVektor/GeoDanmark60_NOHIST_GML3/1.0.0/Wfs?${params}&SERVICE=WFS&REQUEST=GetFeature`
    let featureRequest = new WFS().writeGetFeature({
      srsName: 'EPSG:25832',
      featureNS,
      featurePrefix,
      featureTypes: ['Bygning'],
      outputFormat: 'xml',
      filter: filter
    })
    let xml = await this.fetch(url, {
      method: 'post',
      expects: 'xml',
      // eslint-disable-next-line no-undef
      body: new XMLSerializer().serializeToString(featureRequest)
    })
    xml = this.santizeXml(xml)

    const format = new GML3()
    const features = format.readFeatures(xml)
    const text = new GeoJSON().writeFeatures(features)
    let geojson = JSON.parse(text)
    return geojson
  }

  async getBbrUuidsFromGeometry(geometry) {
    try {
      if (typeof geometry === 'undefined')
        return null
      let bbrgeometry = geometry
      let featureNS = 'http://data.gov.dk/schemas/geodanmark60/2/gml3'
      let featurePrefix = 'gdk60'
      const params = Object.keys(this.authParamsDatafordeler).map(key => key + '=' + this.authParamsDatafordeler[key]).join('&')

      let url = `https://services.datafordeler.dk/GeoDanmarkVektor/GeoDanmark60_NOHIST_GML3/1.0.0/Wfs?${params}&SERVICE=WFS&REQUEST=GetFeature`
      let featureRequest = new WFS().writeGetFeature({
        srsName: 'EPSG:25832',
        featureNS,
        featurePrefix,
        featureTypes: ['Bygning'],
        outputFormat: 'xml',
        filter: intersectsFilter('geometri', bbrgeometry)
      })
      let xml = await this.fetch(url, {
        method: 'post',
        expects: 'xml',
        // eslint-disable-next-line no-undef
        body: new XMLSerializer().serializeToString(featureRequest)
      })
      xml = this.santizeXml(xml)


      const format = new GML3()
      const features = format.readFeatures(xml)
      const text = new GeoJSON().writeFeatures(features)
      let geojson = JSON.parse(text)
      const bbrUiids = []
      geojson.features.forEach((f) => {
        if (typeof f.properties.BBRUUID !== 'undefined')
          bbrUiids.push(f.properties.BBRUUID)
      })
      const uniquebbrUiids = Array.from(new Set(bbrUiids)) //Set data object removes duplicates when passed array 
      return uniquebbrUiids
    } catch (e) {
      return null
    }

  }



}