/**
 * @module
 */
import Searcher from "../Searcher.js"
import ResultType from "../../ResultType.js"
import DawaSearcher from "../DawaSearcher.js"
/**
 * Søger bbr-enheder i datafordeleren.bbr
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.Datafordeler.EnhedsSearcher
 *   _options:
 *     fetcher:
 *       _type: Septima.Search.Datafordeler.Fetcher
 *       _options:
 *         ...
 * @sspath Septima.Search.Datafordeler
 **/
export default class EnhedsSearcher extends Searcher {
  /**
   * @param {Object} options
   * @param {Object} options.fetcher Septima.Search.Datafordeler.Fetcher instance
   **/
  constructor(options) {
    options.usesGeoFunctions = true
    super(options)
    this.fetcher = options.fetcher

    this.source = "DAF"

    this.types = { "bbr_enhed": new ResultType({ id: "bbr_enhed", singular: "Enhed", plural: "Enheder", queryBehaviour: "none" }) }
    this.registerType(this.source, this.types.bbr_enhed)
    this.authParamsDatafordeler = options.authParamsDatafordeler || {
      username: this.fetcher.username,
      password: this.fetcher.password
    }
    this.dawaSearcher = new DawaSearcher({})
  }
  
  async fetchData(query, caller) {
    caller.fetchSuccess(this.createQueryResult())
  }
  
  async get(id, type) {
    let queryResult
    switch (type) {
      case "bbr_enhed": {
        let bbrEnhedResponse = await this.fetcher.read("bbr", "bbr", "enhed", { id: id }, this.getLogger())
        if (bbrEnhedResponse.length == 1) {
          queryResult = await this.enhedToQueryResult(bbrEnhedResponse[0])
          let result = queryResult.getResults()[0]
          return result
        }
      }
    }
    return null
  }
  
  async enhedToQueryResult(bbrData) {
    let queryResult = this.createQueryResult()
    let adressAccessId = bbrData.adresseIdentificerer
    let adressResult = await this.dawaSearcher.get(adressAccessId, "enhedsadresse")
    let anvendelseTekst = this.fetcher.findBbrKode("EnhAnvendelse", bbrData.enh020EnhedensAnvendelse)
    let title = adressResult.title
    let result = queryResult.addResult(this.source, this.types.bbr_enhed.id, title, anvendelseTekst, adressResult.geometry, bbrData)
    result.id = bbrData.id_lokalId
    return queryResult
  }



}