/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"


export default class BbrSagProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.buttonText=Bygninger] Titel
   * @param {Object} options.bygningssearcher Datafordeler.bygningssearcher
   **/
  constructor(options = {}) {

    let defaultOptions = {
      buttonText: "BBR sager"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return (result.typeId === "bbr_bygning")
    }

    this.fetcher = options.fetcher
  }
  async myHandler(result) {
    //let source = result.source
    let typeId = result.typeId

    if (typeId === "bbr_bygning")
      return await this.getSagerForBygning(result)

  }
  async getSagerForBygning(result) {
    let bygningid = result.id
    const bbrsagerResponse = await this.fetcher.read("bbr", "bbr", "bbrsag", { Bygning: bygningid }, this.getLogger())
    let items = this.sagerTodetails(bbrsagerResponse, "Sager for bygning")
    return items

  }
  sagerTodetails(sager, header) {
    if (sager.length > 1) {
      let detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: header,
        infoItemsHeaders: [
          { type: "labelvalue", label: "Byggesagsnummer" },
          { type: "labelvalue", label: "Byggesagsdato", valueformat: "iso-date" },
          { type: "labelvalue", label: "Byggetilladelsesdato",valueformat: "iso-date"  },
          { type: "labelvalue", label: "Påbegyndelsesdato", valueformat: "iso-date" },
          { type: "labelvalue", label: "brugtagningsTilladelse", valueformat: "iso-date" },
          { type: "labelvalue", label: "FuldførelseAfByggeri", valueformat: "iso-date" },
          { type: "labelvalue", label: "Byggesagskode" },
          { type: "labelvalue", label: "DatoForModtagelseAfAnsøgningOmByggetilladelse",valueformat: "iso-date"},
          { type: "labelvalue", label: "Litra"},
          { type: "labelvalue", label: "Status"},
          { type: "labelvalue", label: "Virkning fra",valueformat: "iso-date"}]
      })
      for (let sag of sager) {

        /*
        "datafordelerOpdateringstid": "2021-04-01T22:20:48.427816+02:00",
          "forretningshændelse": "Byggesag",
          "forretningsområde": "54.15.05.05",
          "forretningsproces": "11",
          "id_lokalId": "0de5b1a8-97af-49a2-abd7-3f455d34d3e2",
          "id_namespace": "http://data.gov.dk/bbr/bbrsag",
          "kommunekode": "0101",
          "registreringFra": "2018-02-16T18:58:00.000119+01:00",
          "registreringsaktør": "BBR",
          "sag001Byggesagsnummer": "704732",
          "sag002Byggesagsdato": "2017-10-27T00:00:00.000000+02:00",
          "sag003Byggetilladelsesdato": "2017-10-27T00:00:00.000000+02:00",
          "sag005Påbegyndelsesdato": "2017-10-31T00:00:00.000000+01:00",
          "sag006IbrugtagningsTilladelse": "2017-12-14T00:00:00.000000+01:00",
          "sag010FuldførelseAfByggeri": "2017-11-14T00:00:00.000000+01:00",
          "sag012Byggesagskode": "3",
          "sag024DatoForModtagelseAfAnsøgningOmByggetilladelse": "2017-06-28T00:00:00.000000+02:00",
          "sag025DatoForFyldestgørendeAnsøgning": "2017-06-28T00:00:00.000000+02:00",
          "sag032Litra": "",
          "status": "9",
          "virkningFra": "2017-12-15T08:00:02.662871+01:00",
          "virkningsaktør": "Registerfører",
          */
        detailItemsList.append({
          type: 'labelvalue',
          infoItems: [
            {type: "labelvalue", value: sag.sag001Byggesagsnummer},
            {type: "labelvalue", value: sag.sag002Byggesagsdato,valueformat: "iso-date" },
            {type: "labelvalue", value: sag.sag003Byggetilladelsesdato,valueformat: "iso-date" },
            { type: "labelvalue", value: sag.sag005Påbegyndelsesdato,valueformat: "iso-date" },
            { type: "labelvalue", value: sag.sag006IbrugtagningsTilladelse,valueformat: "iso-date" },
            { type: "labelvalue", value: sag.sag010FuldførelseAfByggeri,valueformat: "iso-date" },
            { type: "labelvalue", value: this.fetcher.findBbrKode("Byggesagskode", sag.sag012Byggesagskode)},
            { type: "labelvalue", value: sag.sag024DatoForModtagelseAfAnsøgningOmByggetilladelse,valueformat: "iso-date" },
            {type: "labelvalue", value: sag.sag032Litra},
            {type: "labelvalue", value: this.fetcher.findBbrKode("Livscyklus", sag.status)},
            {type: "labelvalue", value: sag.virkningFra,valueformat: "iso-date"}]         
        })
      }
      return [detailItemsList.asItem()]


    }else
      return [{type: "labelvalue", label: "BBR sager", value: "Ingen sager fundet"}] 

  }

}