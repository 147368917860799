/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
/**
 * Viser info for geoFA objekter
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
    plansystemsearcher:
      _type: Septima.Search.geoFa.T5710InfoProvider
      _options:
        targets:
          - t_5710_born_skole_dis_t
      detailhandlers:
        - _type: "Septima.Search.geoFa.T5710InfoProvider"
          _options:
            fields: ["temanavn","cvr_navn","cvr_kode","cvrlink","starttrin","slutttrin","statuskode"]
 * @example <caption> JS options: </caption>
 options = {
    fields: [temanavn','udd_distrikt_navn','cvr_navn','cvr_kode','starttrin','slutttrin','statuskode']
    }
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * planSystemSearcher.addDetailHandlerDef(new Septima.Search.T5710InfoProvider(options))
 *
 * @example <caption>ES6:</caption>
 * import T5710InfoProvider from './searchers/geoFa/detailhandlers/T5710InfoProvider.js'
 * planSystemSearcher.addDetailHandlerDef(new T5710InfoProvider(options))
 * @sspath Septima.Search.GeoFa
 **/

export default class T5710InfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields] Felter, der skal medtages i den viste info
   * @param {string[]} [options.fields= ["temanavn","cvr_navn","cvr_kode","cvrlink","starttrin","slutttrin","statuskode"]] For skoledistrikter. BEMÆRK  slutttrin har et t for meget

   **/
  constructor(options) {
    let defaultOptions = {
      buttonText: "GeoFA info",
      more: true
    }
    super(Object.assign(defaultOptions, options))
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.handler

    //Default fields
    this.fields = {}
    this.fields = ["temanavn", "udd_distrikt_navn", "cvr_kode", "cvr_navn", "cvrlink", "starttrin", "slutttrin","statuskode"]

    //Read fields from options
    if (typeof options !== 'undefined' && typeof options.fields !== 'undefined')
      this.fields = options.fields
  }

  isApplicable(result) {
    return (result.source === "geofa" && result.typeId === "t_5710_born_skole_dis_t")
  }

  async handler(result) {



    let items = []

    if (result.typeId === "t_5710_born_skole_dis_t")
      for (let field of this.fields) {
        let item = await this.createItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }

    return items

  }

  createItem(result, field) {
    let props = result.data.properties
    if (field === 'udd_distrikt_navn')
      return {
        type: 'labelvalue',
        label: 'Navn',
        value: props.udd_distrikt_navn
      }
    else if (field === 'temanavn')
      return {
        type: 'labelvalue',
        label: 'Tema',
        value: props.temanavn

      }
    else if (field === 'cvr_kode')
      return {
        type: 'labelvalue',
        label: 'CVR Kode',
        value: props.cvr_kode

      }
    else if (field === 'cvr_navn')
      return {
        type: 'labelvalue',
        label: 'CVR Navn',
        value: props.cvr_navn

      }
    else if (field === 'cvrlink') {
      let cvrNummer = props.cvr_kode
      return {
        type: 'link',
        icon: icons.exlink,
        label: `${props.cvr_navn} på virk.dk`,
        link: `http://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${cvrNummer}`,
        linkTitle: `Virksomheden (${cvrNummer}) på virk.dk`
      }

    } else if (field === 'starttrin')
      return {
        type: 'labelvalue',
        label: 'Starttrin',
        value: props.starttrin

      }
    else if (field === 'slutttrin')
      return {
        type: 'labelvalue',
        label: 'Sluttrin',
        value: props.slutttrin

      }
    else if (field === 'statuskode')
      return {
        type: 'labelvalue',
        label: 'Status',
        value: this.getStatusTekstFraKode(props.statuskode)

      }
    return

  }

  getStatusTekstFraKode (kode) { 
    const statuskoder = {
      "0":  {tekst:"Ukendt"},
      "1":  {tekst:"Kladde"},
      "2": {tekst:"Forslag"},
      "3": {tekst:"Gældende"},
      "4":  {tekst:"Ikke gældende / aflyst"}
      
    }
    if (statuskoder[kode])
      return statuskoder[kode]["tekst"]
    else
      return kode || 'Ikke specificeret'
  }
}