import domutil from '../../../../lib/domutil.js'
import icons from '../../resources/icons.js'
import Searcher from '../Searcher.js'
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"

export default class SqSearcher extends Searcher {
  constructor(options) {
    if (options === undefined)
      throw "New Septima.Search.SqSearcher(options): Options missing."

    super(Object.assign({
      usesGeoFunctions: true
    },
    options))

    if (!options.source)
      options.source = "WebGis"

    this.source = options.source

    this.ajaxURL = ''
    if (options.host)
      this.ajaxURL = options.host

    this.layers = options.layers
    this.profile = options.profile
    this.profileQuery = options.profileQuery

    this.buffer = 0
    if (options.buffer)
      this.buffer = options.buffer

    this.sessionId = ""
    this.applicationId = ""
    if (options.sessionId)
      this.sessionId = options.sessionId
    else
      if (options.applicationId)
        this.applicationId = options.applicationId
  }

  async sq(query) {
    //http://spatialsuite400.kpc.asus:8080/spatialmap?page=create-session&applicationid=cbkort-session-test-application&outputformat=json
    if (this.sessionId === "")
      if (this.applicationId === "")
        return this.createQueryResult()
      else
        try{
          let data = await this.fetch(this.ajaxURL + '/spatialmap?page=create-session&applicationid='+this.applicationId+'&outputformat=json')
          this.sessionId = data.row[0].sessionid
        }catch(e) {
          throw ("SqSearcher ("+this.ajaxURL+") failed: " + e)
        }

    const wkt = query.wkt
    const params = {
      wkt: wkt,
      page: "s4spatialquery-getresult",
      profile: this.profile,
      profilequery: this.profileQuery,
      distance: this.buffer,
      sessionid: this.sessionId,
      layers: this.layers,
      "cbinfo.output": 'json',
      outputformat: 'json'
    }
    let fetch2Use
    let fetchOptions = {data: params}
    if (this.ajaxURL === "") {
      fetch2Use = this.fetch
      fetchOptions.method = 'post'
      fetchOptions.credentials = "include"
    } else {
      fetch2Use = this.fetch
    }
    let data = await fetch2Use(this.ajaxURL + '/spatialmap', fetchOptions)
    data.query = query
    const queryResult = this.transformSqResultToList(data)
    const results = queryResult.getResults()
    if (results.length > 0) {
      let geometries = await this.getGeometries()
      for (let result of results)
        result.geometry = geometries[this.getPcolId(result.data)]
    }
    return queryResult
  }

  transformSqResultToList(sqResult) {
    const queryResult = this.createQueryResult()
    try {
      //row[0].row[0].row er array af targets
      let targets = sqResult.row[0].row[0].row
      //row[0].row[0].row[i].row er target
      //row[0].row[0].row[i].row[0] overskrift
      //row[0].row[0].row[i].row[10 + n].row featureN
      for (let target of targets)
        try {
          let properties = target.row
          let title = properties[0].overskrift
          for (let t = 10; t < properties.length; t++) {
            let feature = properties[t]
            let heading = this.getHeading(feature)
            if (heading !== null) {
              let result = queryResult.addResult(this.source, title, heading, title, null, feature)
              result.target = title
              result.distance = 0
              result.isComplete = true
            }
          }
        } catch (e) {
          // catch all
        }

    } catch (e) {
      // catch all
    }
    return queryResult
  }

  getHeading(feature) {
    if (typeof feature.row !== 'undefined')
      for (let row of feature.row)
        if (row.format == 'heading')
          return row.value
    return null
  }

  getPcolId(feature) {
    for (let row of feature.row)
      if (row.format == 'org-pcolid')
        return row.value
    return ""
  }

  async getGeometries() {
    let params = {
      page: "s4spatialquery-getoutput",
      sessionid: this.sessionId
    }
    let fetch2Use
    let fetchOptions = {data: params}
    if (this.ajaxURL === "") {
      fetch2Use = this.fetch
      fetchOptions.method = 'post'
      fetchOptions.credentials = "include"
    } else {
      fetch2Use = this.fetch
    }
    let data = await fetch2Use(this.ajaxURL + '/spatialmap?cbinfo.output=json&outputformat=json', fetchOptions)
    let geometries = {}
    let targets = data.row[0].row[0].row
    for (let target of targets) {
      //row[0].row[0].row[i]
      let features = target.row
      if (typeof features !== 'undefined')
        for (let feature of features) {
          let wkt = feature.shape_wkt
          let pcolid = feature._pcolid
          geometries[pcolid] = this.translateWktToGeoJsonObject(wkt)
        }
    }
    return geometries
  }

  hasDetails(feature) {
    if (typeof feature.hasDetails === "undefined") {
      feature.hasDetails = false
      for (let row of feature.row)
        if (row.format === '' && row.value && row.value !== '') {
          feature.hasDetails = true
          return true
        }
    } else {
      return feature.hasDetails
    }
  }

  hasDetailHandlersForResult(result) {
    const feature = result.data
    return this.hasDetails(feature)
  }

  getDetailHandlersForResult(result) {
    const feature = result.data
    let detailHandlerDefs = []

    if (this.hasDetails(feature)) {
      const detailsHandler = () => {
        return new Promise(
          (resolve) => {
            const items = []
            for (let row of feature.row)
              if (row.format === '' && row.value && row.value !== '') {
                let label = row.label
                let value = row.value
                items.push({type: "labelvalue", label: label, value: value})
              }

            resolve(items)
            //resolve(detailsContent.formatItems(items));
          })
      }
      let detailHandlerDef = new DetailsHandlerDef(
        {
          "buttonText": "Attributter",
          "buttonImage": icons.details.moreHeader,
          "handler": detailsHandler,
          "more": true
        }
      )
      detailHandlerDefs.push(detailHandlerDef)
    }
    return detailHandlerDefs
  }

  getCustomButtonsForResult(result) {
    const feature = result.data
    let customButtonDef
    const customButtonDefs = []
    for (let row of feature.row)
      if (row.format === 'hyperlink') {
        customButtonDef = {
          "buttonText": row.label,
          "buttonImage": icons.linksymbol,
          "callBack": () => {
            window.open(row.value, "_blank")
          }
        }
        customButtonDefs.push(customButtonDef)
      } else if (row.format === 'javascriptlink') {
        customButtonDef = {
          "buttonText": row.label,
          "buttonImage": icons.linksymbol,
          "callBack": () => {
            domutil.globalEval(row.value)
          }
        }
        customButtonDefs.push(customButtonDef)
      }
    return customButtonDefs
  }

}
