/**
 * @module
 */
import ResultType from "../../ResultType.js"
import Searcher from "../Searcher.js"
import * as reproject from "../../util/reproject.js"
import { getWKTParser } from "../../util/getWKTParser.js"


/**
 *
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
  _type: septima.Search.GeoFa.T5710Searcher
  _options:
    minimumShowCount: 3
 * @example <caption> JS options: </caption>
 options = {
 
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.GeoFa.T5710Searcher(options))
 *
 * @example <caption>ES6:</caption>
 * import T5710Searcher from './searchers/GeoFa/T5710Searcher.js'
 * controller.addSearcher(new T5710Searcher(options))
 */


export default class T5710Searcher extends Searcher {
  /**
   *
   * @param {Object} options T5710Searcher expects these properties:
   * @param {Object} [options.fetcher] Septima.Search.GeoFa.Fetcher
   * @sspath Septima.Search.GeoFa
   */
  constructor(options) {
    let defaultOptions = {
      udd_distrikt_type_kode: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 98, 99],
      statuskode: [0, 1, 2, 3, 4]
    }
    let finalOptions = Object.assign(defaultOptions, options)
    super(finalOptions)

    this.source = "geofa"
    this.udd_distrikt_type_kode = finalOptions.udd_distrikt_type_kode
    this.statuskode = finalOptions.statuskode

    if (!options.fetcher)
      throw new Error("T5710Searcher expects options.fetcher")

    this.fetcher = options.fetcher

    this.types = {
      "t_5710_born_skole_dis_t": new ResultType({ id: "t_5710_born_skole_dis_t", singular: "Børne- og skoledistrikt", plural: "Børne- og skoledistrikter", queryBehaviour: "search", featuretype: "fkg.t_5710_born_skole_dis" })
    }

    this.typeName = this.types.t_5710_born_skole_dis_t.values.featuretype
    this.featuretypeId = this.types.t_5710_born_skole_dis_t.values.id

    this.registerType(this.source, this.types.t_5710_born_skole_dis_t)

    reproject.registerCrs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      let gotResult = await this.get(result.id, result.typeId)
      result.geometry = gotResult.geometry
      result.data = gotResult.data
      result.isComplete = true
      return result
    }
  }


  async fetchData(query, caller) {
    const queryResult = this.createQueryResult()
   
    let featuresArray = await this.fetcher.search(`SELECT * FROM ${this.typeName} WHERE udd_distrikt_navn ilike '${query.queryString}%' and statuskode IN (${this.statuskode}) and udd_distrikt_type_kode IN (${this.udd_distrikt_type_kode})`)
    for (let feature of featuresArray.features)      
      this.addFeatureToQueryResult(feature, queryResult)

    caller.fetchSuccess(queryResult)
  }


  async get(id, type) {
    let queryResult = this.createQueryResult()

    if (type === this.featuretypeId) {
      let features = await this.fetchById(id)
      if (features.length === 1)
        this.addFeatureToQueryResult(features[0], queryResult)
    } else {
      throw new Error('Whoops! get(id) is not implemented for ' + type)
    }

    let results = queryResult.getResults()
    if (results.length === 1)
      return results[0]
  }

  addFeatureToQueryResult(feature, queryResult) {

    let typeId = feature.properties.temanavn
    let title
    let description


    title = feature.properties.udd_distrikt_navn
    description = `${feature.properties.udd_distrikt_type}: ${feature.properties.starttrin} - ${feature.properties.slutttrin} `
    
    let geometry = feature.geometry
    geometry.crs = {
      "type": "name",
      "properties": {
        "name": "epsg:25832"
      }
    }
    let result = queryResult.addResult(this.source, typeId, title, description, geometry, feature)
    result.id = feature.properties.objekt_id
    return result
  }




  async sq(query) {
    let queryGeometry = reproject.reproject(query.geometry, null, "EPSG:25832")
    let wktParser = getWKTParser()
    const wkt = wktParser.convert(queryGeometry)
    let sql = `SELECT * FROM ${this.typeName} WHERE st_intersects(geometri,st_setsrid(st_geometryFromText('${wkt}'),25832)) and statuskode IN (${this.statuskode}) and udd_distrikt_type_kode IN (${this.udd_distrikt_type_kode})`
    let features = await this.fetcher.search(sql)
    return this.createQueryResultFromFeatures(features)
  }

  createQueryResultFromFeatures(features) {
    let queryResult = this.createQueryResult()
    for (let feature of features.features)
      this.addFeatureToQueryResult(feature, queryResult)
    return queryResult
  }


  async fetchById(id) {
    let response = await this.fetcher.search(`SELECT * FROM ${this.typeName} WHERE objekt_id='${id}' and statuskode IN (${this.statuskode})`)
    if (response && response.features)
      return response.features
    else
      return []
  }

}
