import {VejAPI} from "./Vej.dawa.api.js"
import {AdgangsAdresseAPI} from "./AdgangsAdresse.dawa.api.js"
import {EnhedsAdresseAPI} from "./EnhedsAdresse.dawa.api.js"

const serviceUrl = "https://api.dataforsyningen.dk"

export class API {
  constructor(options = {}) {
    this.kommunekode  = "*"
    if (typeof options.kommunekode !== 'undefined') 
      this.kommunekode = options.kommunekode.toString()

    this.vejAPI = new VejAPI({kommunekode: this.kommunekode, serviceUrl})
    this.adgangsAdresseAPI = new AdgangsAdresseAPI({kommunekode: this.kommunekode, serviceUrl})
    this.enhedsAdresseAPI = new EnhedsAdresseAPI({kommunekode: this.kommunekode, serviceUrl})
  }

  /*
   * @param {Object} queryOptions 
   * @param {string} queryOptions.query
   * @param {string} queryOptions.limit
   * @param {string} queryOptions.goal
   * @param {string} queryOptions.showStreets
  */
  async query(queryOptions) {
    let hits = []
    //dawa må maks kaldes med 100 tegn - rigelig margen
    if (queryOptions.query.length < 80) {
      let goal = queryOptions.goal // "enhedsadresse" : "adgangsadresse"
      //Hvis der er indtastet en gyldig vejkode så returnér newQuery med vejen
      if (this.queryCouldBeVejkode(queryOptions.query)) {
        let vejStykkeHits = await this.vejAPI.getVejStykker({kode: queryOptions.query})
        if (typeof vejStykkeHits!== 'undefined') {
          for (let vejStykkeHit of vejStykkeHits)
            hits.push({type: "vejnavnpostnummer", vejnavn: vejStykkeHit.vejnavn, postnummer: vejStykkeHit.postnummer, postdistrikt: vejStykkeHit.postdistrikt})
        }
      } else {
        let vejQuery = this.vejAPI.query(queryOptions)
        let adgangsAdresseQuery = this.adgangsAdresseAPI.query(queryOptions)
        if (goal === 'adgangsadresse') {
          hits = await this.getHitsFromQueries(vejQuery, adgangsAdresseQuery, null, queryOptions)
        } else {
          let enhedsAdresseQuery = this.enhedsAdresseAPI.query(queryOptions)
          hits = await this.getHitsFromQueries(vejQuery, adgangsAdresseQuery, enhedsAdresseQuery, queryOptions)
        }
      }
    }
    return hits
  }
  
  async get (id, type) {
    if (type === 'enhedsadresse')
      return await this.enhedsAdresseAPI.get(id)
    else if (type === 'adgangsadresse')
      return await this.adgangsAdresseAPI.get(id)
    else if (type === 'vej')
      return await this.vejAPI.get(id)
  }

  async gets (ids, type) {
    if (type === 'enhedsadresse')
      return await this.enhedsAdresseAPI.gets(ids)
    else if (type === 'adgangsadresse')
      return await this.adgangsAdresseAPI.gets(ids)
    else if (type === 'vejstykke')
      return await this.vejAPI.gets(ids)
  }
  
  async getEnhedsAdresserForAdresse(adresseId) {
    return await this.enhedsAdresseAPI.getEnhedsAdresserForAdresse(adresseId)
  }
  
  async getVejForNavnPostnummer(navn, postnummer) {
    return await this.vejAPI.getVejForNavnPostnummer(navn, postnummer)
  }

  async getHusnumreForVej(vejkode, kommunekode) {
    return await this.adgangsAdresseAPI.getHusnumreForVej(vejkode, kommunekode)
  }
  
  queryCouldBeVejkode(query) {
    if (query.length === 4 ) {
      let parseResult = parseInt(query)
      if( !isNaN(parseResult)) 
        return true
    }
    return false
  }

  testGetInfo() {
    // Test examples
    this.getRoadCode("123")       // "123"
    this.getRoadCode("1234")      // "1234"
    this.getRoadCode("12")        // null
    this.getRoadCode("12345")     // null
    this.getRoadCode("lærkevej 6") // null
    this.getRoadCode("1200 abc")  // null
    // Test examples
    this.getStreetName("lærkevej 6")            // "lærkevej"
    this.getStreetName("lærkevej 6a")           // "lærkevej"
    this.getStreetName("gammel lærkevej")       // "gammel lærkevej"
    this.getStreetName("viborgvej 6, 1200")     // "viborgvej"
    this.getStreetName("viborgvej 1200, 6")     // "viborgvej"
    this.getStreetName("viborgvej 6 1200")      // "viborgvej"
    this.getStreetName("adresse med 1234 postnr")// "adresse med"
    // Test examples
    this.getHouseNumber("lærkevej 6")         // "6"
    this.getHouseNumber("lærkevej 6a")       // "6a"
    this.getHouseNumber("gammel lærkevej")    // null
    this.getHouseNumber("viborgvej 6, 1200")  // "6"
    this.getHouseNumber("lærkevej 6")            // "6"
    this.getHouseNumber("lærkevej 6a")           // "6a"
    this.getHouseNumber("gammel lærkevej")       // null
    this.getHouseNumber("viborgvej 6, 1200")     // "6"
    this.getHouseNumber("1200 viborgvej 6")      // null
    this.getHouseNumber("viborgvej 6 1200")      // "6"
    this.getHouseNumber("6 lærkevej")            // null

    // Test examples
    this.getPostalCode("lærkevej 6")            // null
    this.getPostalCode("lærkevej 6a")            // null
    this.getPostalCode("gammel lærkevej")        // null
    this.getPostalCode("viborgvej 6, 1200")      // "1200"
    this.getPostalCode("viborgvej 1200, 6")      // "1200"
    this.getPostalCode("viborgvej 6 1200")       // "1200"
    this.getPostalCode("adresse med 1234 postnr")// "1234"    
    this.getPostalCode("lærkevej 6")             // null
    this.getPostalCode("lærkevej 6a")            // null
    this.getPostalCode("gammel lærkevej")        // null
    this.getPostalCode("viborgvej 6, 1200")      // "1200"
    this.getPostalCode("viborgvej 1200, 6")      // "1200"
    this.getPostalCode("viborgvej 6 1200")       // "1200"
    this.getPostalCode("1234")                   // null
    this.getPostalCode("adresse med 1234 postnr")// "1234"
  }
    
  getRoadCode(address) {
    // Regular expression to match exactly three or four digits only
    const roadCodePattern = /^\d{3,4}$/

    // Test if the entire address string matches the road code pattern
    const match = address.match(roadCodePattern)

    // Return the matched road code or null if no match is found
    return match ? match[0] : null
  }
  
  getStreetName(address) {
    // Regular expressions to match house numbers and postal codes
    const houseNumberPattern = /\b\d+[a-zA-Z]?\b/
    const postalCodePattern = /\b\d{4}\b/

    // Find the position of the house number and postal code in the address string
    const houseNumberMatch = address.match(houseNumberPattern)
    const postalCodeMatch = address.match(postalCodePattern)

    // Get the position of the first occurrence of house number or postal code
    const houseNumberIndex = houseNumberMatch ? address.indexOf(houseNumberMatch[0]) : -1
    const postalCodeIndex = postalCodeMatch ? address.indexOf(postalCodeMatch[0]) : -1

    // Determine the cutoff index for the street name
    let cutoffIndex = -1
    if (houseNumberIndex !== -1 && postalCodeIndex !== -1) {
      cutoffIndex = Math.min(houseNumberIndex, postalCodeIndex)
    } else if (houseNumberIndex !== -1) {
      cutoffIndex = houseNumberIndex
    } else if (postalCodeIndex !== -1) {
      cutoffIndex = postalCodeIndex
    }

    // If there is no house number or postal code, return the entire address
    if (cutoffIndex === -1) {
      return address.trim()
    }

    // Return the substring from the start to the cutoff index, trimmed of any trailing whitespace
    return address.substring(0, cutoffIndex).trim()
  }
  
  getHouseNumber(address) {
    // Regular expression to match a street name followed by a house number
    const houseNumberPattern = /\b[A-Za-zæøåÆØÅ\s]+(\d{1,3}[a-zA-Z]?)\b/

    // Match the address string with the pattern
    const match = address.match(houseNumberPattern)

    // If there's a match, extract the house number
    if (match) {
      return match[1] // The house number is captured in the first group
    }

    // Return null if no match is found
    return null
  }

  getPostalCode(address) {
    // Regular expression to match a street name followed by a postal code
    const postalCodePattern = /\b\D+\s+\d{4}\b/

    // Match the address string with the pattern
    const match = address.match(postalCodePattern)

    // If there's a match, extract the postal code
    if (match) {
      // Extract the postal code from the match
      const postalCodeMatch = match[0].match(/\d{4}/)
      return postalCodeMatch ? postalCodeMatch[0] : null
    }

    // Return null if no match is found
    return null
  }

  async getHitsFromQueries(vejQuery, adgangsAdresseQuery, enhedsAdresseQuery, queryOptions) {
    /*
    queryOptions = {
      limit: query.limit + 3,
      query: query.queryString == "" ? "a" : query.queryString,
      goal: goal, // "enhedsadresse" "adgangsadresse" "adgangellerenhedsadresse"
      showStreets: this.showOneStreet
    }
     */
    
    let hits = []
    
    // eslint-disable-next-line no-unused-vars
    let vejNavn= this.getStreetName(queryOptions.query)
    // eslint-disable-next-line no-unused-vars
    let postNummer = this.getPostalCode(queryOptions.query)
    // eslint-disable-next-line no-unused-vars
    let husNummer = this.getHouseNumber(queryOptions.query)

    // eslint-disable-next-line no-unused-vars
    let vejnavnHits = await vejQuery.getVejnavneHits()
    let vejnavnCount = await vejQuery.getVejnavnCount()

    // eslint-disable-next-line no-unused-vars
    let vejnavnPostnummerHits = await vejQuery.getVejnavnPostnummerHits(queryOptions.limit)
    let vejnavnPostnummerCount = await vejQuery.getVejnavnPostnummerCount()

    let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHits(queryOptions.limit)
    let adgangsAdresseCount = await adgangsAdresseQuery.getCount()

    if (husNummer) {
      if (queryOptions.goal === "adgangsadresse") {
        if (queryOptions.showStreets && adgangsAdresseHits.length == 1) {
          let adgangsAdresseHit = adgangsAdresseHits[0]
          hits.push(await vejQuery.getVejHit(adgangsAdresseHit.kommunekode, adgangsAdresseHit.vejkode))
        }
        hits = hits.concat(adgangsAdresseHits)
      } else if (queryOptions.goal === "adgangellerenhedsadresse" && adgangsAdresseHits.length > 0) {
        if (adgangsAdresseHits.length == 1) {
          let adgangsAdresseHitsMedInfo = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(queryOptions.limit)
          let adgangsAdresseHit = adgangsAdresseHits[0]
          let adgangsAdresseHitMedInfo = adgangsAdresseHitsMedInfo[0]
          /*
          if (queryOptions.showStreets) {
            hits.push(await vejQuery.getVejHit(adgangsAdresseHit.kommunekode, adgangsAdresseHit.vejkode))
          }
           */
          adgangsAdresseHit.type = "lonelyadgangsadresseinfo"
          hits.push(adgangsAdresseHit)
          hits = hits.concat(adgangsAdresseHitMedInfo.enhedsAdresser)
        } else {
          hits = hits.concat(adgangsAdresseHits)
        }
      } else if (queryOptions.goal === "enhedsadresse" && adgangsAdresseHits.length == 1) {
        let adgangsAdresseHitsMedInfo = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(queryOptions.limit)
        hits = hits.concat(adgangsAdresseHitsMedInfo[0].enhedsAdresser)
      }
    } else {
      if (adgangsAdresseHits.length == 1) {
        if (queryOptions.goal === "adgangsadresse") {
          if (queryOptions.showStreets) {
            let adgangsAdresseHit = adgangsAdresseHits[0]
            hits.push(await vejQuery.getVejHit(adgangsAdresseHit.kommunekode, adgangsAdresseHit.vejkode))
          }
          hits = hits.concat(adgangsAdresseHits)
        } else if (queryOptions.goal === "adgangellerenhedsadresse") {
          let adgangsAdresseHitsMedInfo = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(queryOptions.limit)
          let adgangsAdresseHit = adgangsAdresseHits[0]
          let adgangsAdresseHitMedInfo = adgangsAdresseHitsMedInfo[0]
          /*
          if (queryOptions.showStreets) {
            hits.push(await vejQuery.getVejHit(adgangsAdresseHit.kommunekode, adgangsAdresseHit.vejkode))
          }
           */
          adgangsAdresseHit.type = "lonelyadgangsadresseinfo"
          hits.push(adgangsAdresseHit)
          hits = hits.concat(adgangsAdresseHitMedInfo.enhedsAdresser)
        } else if (queryOptions.goal === "enhedsadresse") {
          let adgangsAdresseHitsMedInfo = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(queryOptions.limit)
          hits = hits.concat(adgangsAdresseHitsMedInfo[0].enhedsAdresser)
        }
      } else {
        if ((adgangsAdresseCount > 0 && adgangsAdresseCount <= Math.min(queryOptions.limit, 10)) || vejnavnPostnummerCount == 0) {
          if (queryOptions.goal === "adgangellerenhedsadresse" || queryOptions.goal === "enhedsadresse") {
            let adgangsAdresseHitsMedInfo = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(queryOptions.limit)
            if (queryOptions.goal === "enhedsadresse") {
              for (let adgangsAdresseHit of adgangsAdresseHitsMedInfo) {
                if (adgangsAdresseHit.enhedsAdresser.length == 0) {
                  if (adgangsAdresseHit.skyggeAdresse) {
                    hits.push(adgangsAdresseHit.skyggeAdresse)
                  }
                } else {
                  hits.push(adgangsAdresseHit)
                }
              }
            } else {
              hits = hits.concat(adgangsAdresseHitsMedInfo)
            }
          } else {
            hits = hits.concat(adgangsAdresseHits)
          }
        } else {
          if (vejnavnPostnummerCount === 1) {
            if (queryOptions.goal === "adgangellerenhedsadresse" || queryOptions.goal === "enhedsadresse") {
              let adgangsAdresseHitsMedInfo = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(queryOptions.limit)
              if (queryOptions.goal === "enhedsadresse") {
                for (let adgangsAdresseHit of adgangsAdresseHitsMedInfo) {
                  if (adgangsAdresseHit.enhedsAdresser.length == 0) {
                    if (adgangsAdresseHit.skyggeAdresse) {
                      hits.push(adgangsAdresseHit.skyggeAdresse)
                    }
                  } else {
                    hits.push(adgangsAdresseHit)
                  }
                }
              } else {
                hits = hits.concat(adgangsAdresseHitsMedInfo)
              }
            } else {
              if (queryOptions.showStreets)
                hits.push(await vejQuery.buildVejHit())
              hits = hits.concat(adgangsAdresseHits)
            }
          } else if (vejnavnPostnummerCount > 1) {
            if (vejnavnPostnummerCount <= queryOptions.limit || vejnavnCount == 1 || vejnavnCount == 0) {
              hits = await vejQuery.getVejnavnPostnummerHits(queryOptions.limit)
            } else if (vejnavnCount > 0) {
              hits = await vejQuery.getVejnavneHits(queryOptions.limit)
            }
          }
        }
      }
    }
    return hits
    /*
    if (queryOptions.goal === "adgangellerenhedsadresse") {
      
    }
    
    if (queryOptions.goal === "enhedsadresse") {
      
    }


    if (vejnavnPostnummerCount > 1) {
      //Ikke-unikt vejnavn
      if (vejnavnPostnummerCount <= queryOptions.limit || vejnavnCount == 1 || vejnavnCount == 0)
        hits = await vejQuery.getVejnavnPostnummerHits(queryOptions.limit)
      else if (vejnavnCount > 0)
        hits = await vejQuery.getVejnavneHits(queryOptions.limit)
      return hits
    }
    
    if (vejnavnPostnummerCount === 1) {
      if (queryOptions.goal === "adgangsadresse") {
        if (queryOptions.showStreets)
          hits.push(await vejQuery.buildVejHit())
        hits = hits.concat(adgangsAdresseHits)
      } else {
        let adgangsAdresseHitsMedInfo = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(queryOptions.limit)
        if (queryOptions.showStreets)
          hits.push(await vejQuery.buildVejHit())
        if (queryOptions.goal === "adgangellerenhedsadresse") {
          if (adgangsAdresseCount === 1) {
            //unikt husnummer
            let adgangsAdresseHit = adgangsAdresseHitsMedInfo[0]
            adgangsAdresseHit.type = "lonelyadgangsadresseinfo"
            hits.push(adgangsAdresseHit)
            hits = hits.concat(adgangsAdresseHit.enhedsAdresser)
          } else {
            hits = hits.concat(adgangsAdresseHitsMedInfo)
          }
        }
        if (queryOptions.goal === "enhedsadresse") {
          if (adgangsAdresseCount === 1) {
            //unikt husnummer
            hits = hits.concat(adgangsAdresseHitsMedInfo[0].enhedsAdresser)
          } else {
          
            for (let adgangsAdresseHit of adgangsAdresseHitsMedInfo) {
              if (adgangsAdresseHit.enhedsAdresser.length == 0) {
                if (adgangsAdresseHit.skyggeAdresse) {
                  hits.push(adgangsAdresseHit.skyggeAdresse)
                }
              } else {
                hits.push(adgangsAdresseHit)
              }
            }
            
          }
        }
      }
      return hits
    }

    if (adgangsAdresseCount > 1) {
      if (queryOptions.goal === "adgangsadresse") {
        let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHits(queryOptions.limit)
        if (queryOptions.showStreets) {
          let adgangsAdresseHit = adgangsAdresseHits[0]
          hits.push(await vejQuery.getVejHit(adgangsAdresseHit.kommunekode, adgangsAdresseHit.vejkode))
        }
        hits = hits.concat(adgangsAdresseHits)
      } else {
        let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(queryOptions.limit)
        if (queryOptions.showStreets) {
          let adgangsAdresseHit = adgangsAdresseHits[0]
          hits.push(await vejQuery.getVejHit(adgangsAdresseHit.kommunekode, adgangsAdresseHit.vejkode))
        }
        if (queryOptions.goal === "adgangellerenhedsadresse") {
          hits = hits.concat(adgangsAdresseHits) // som forslag, hvis der er enheder på den enkelte / Som resultat, hvis der ikke er enheder
        }
        if (queryOptions.goal === "enhedsadresse") {
          for (let adgangsAdresseHit of adgangsAdresseHits) {
            if (adgangsAdresseHit.enhedsAdresser.length == 0) {
              if (adgangsAdresseHit.skyggeAdresse) {
                hits.push(adgangsAdresseHit.skyggeAdresse)
              }
            } else {
              hits.push(adgangsAdresseHit)
            }
          }
        }
      }
      return hits
    }
    
    if (adgangsAdresseCount === 1) {
      //unikt husnummer
      let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHits(queryOptions.limit)
      if (queryOptions.goal === "adgangsadresse") {
        if (queryOptions.showStreets) {
          let adgangsAdresseHit = adgangsAdresseHits[0]
          hits.push(await vejQuery.getVejHit(adgangsAdresseHit.kommunekode, adgangsAdresseHit.vejkode))
        }
        hits.push(adgangsAdresseHits[0])
      } else {
        let adgangsAdresseHits = await adgangsAdresseQuery.getAdgangsAdresseHitsMedEnhedsInfo(queryOptions.limit)
        let adgangsAdresseHit = adgangsAdresseHits[0]
        if (queryOptions.goal === "adgangellerenhedsadresse") {
          adgangsAdresseHit.type = "lonelyadgangsadresseinfo"
          hits.push(adgangsAdresseHit)
          hits = hits.concat(adgangsAdresseHit.enhedsAdresser)
        }
        if (queryOptions.goal === "enhedsadresse") {
          hits = hits.concat(adgangsAdresseHit.enhedsAdresser)
        }
      }
    
      return hits
    }
    if (queryOptions.goal === "adgangellerenhedsadresse" || queryOptions.goal === "enhedsadresse" ) {
      let enhedsAdresseCount = await enhedsAdresseQuery.getCount()
      if(enhedsAdresseCount > 0) {
        let enhedsAdresseHits = await enhedsAdresseQuery.getEnhedsAdresseHits()
        hits = hits.concat(enhedsAdresseHits)
      }
      return hits
    }
    return hits
        
     */


  }

}


