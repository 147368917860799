/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
import {getWKTParser} from "../../util/getWKTParser.js"
import DetailItemsList from "../../details/DetailItemsList.js"

/**
 * Viser de tre nærmeste resulktater fra et S4Index
 * @sspath Septima.Search
 */
export default class S4NearestHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {boolean} [options.datasources="*"]
   * @sspath Septima.Search
   **/
  constructor(options={}) {
    let defaultOptions = {
      buttonText: "Nærmeste",
      buttonImage: icons.details.moreHeader
    }
    super(Object.assign(defaultOptions, options))

    if (options.s4IndexSearcher)
      this.s4IndexSearcher = options.s4IndexSearcher
    else
      throw "S4NearestHandler needs an s4IndexSearcher"
    
    this.handlerFunction = this.myHandler
    if (! options.targets)
      this.isApplicableFunction = this.myIsApplicable
    
    this.onlyPoints = false
    if (options.onlyPoints)
      this.onlyPoints = true
    
    this.limit = null
    if (options.limit)
      this.limit = options.limit
    
    this.datasources = ["*"]
    if (options.datasources && options.datasources != "*") {
      this.datasources = options.datasources.split(" ")
    }
    this.wktParser = getWKTParser()
  }

  myIsApplicable(result) {
    let applicable = result.geometry// && result.geometry.type == "Point"
    return applicable
  }
  
  async myHandler(result) {
    let items = []
    if (result.geometry) {
      let queryResult = this.s4IndexSearcher.createQueryResult()
      let datasourceResults = await this.s4IndexSearcher.nearest(result.geometry, this.limit, this.datasources)
      for (let datasourceResult of datasourceResults) {
        let datasource = datasourceResult.datasource
        let features = datasourceResult.features
        if (features.length > 0) {
          let detailItemsListOptions = {
            itemType: "result",
            header: features.length == 1 ? datasource.featuretypesingle : datasource.featuretypeplural,
            name: datasource.id,
            isHomogenous: true
          }
          if (datasource.iconuri)
            detailItemsListOptions.image = datasource.iconuri

          let detailItemsList = new DetailItemsList(detailItemsListOptions)

          for (let feature of features) {
            let featureGeometry = this.wktParser.parse(feature.wkt, this.s4IndexSearcher.s4Epsg)
            if (featureGeometry && featureGeometry.type == "MultiPoint" && featureGeometry.coordinates.length == 1) {
              featureGeometry.type = "Point"
              featureGeometry.coordinates = featureGeometry.coordinates[0]
            }
            if ((this.onlyPoints && featureGeometry.type == "Point") || this.onlyPoints == false) {
              let featureResult = queryResult.addResult(this.s4IndexSearcher.source, datasource.id, feature.title, feature.description, featureGeometry, feature)
              featureResult.id = feature.featureid
              if (datasource.iconuri !== undefined)
                featureResult.image = datasource.iconuri
              detailItemsList.append({
                type: "result",
                result: featureResult
              })
            }
          }
          if (detailItemsList.items.length > 0)
            items.push(detailItemsList.asItem())
        }
      }
    }
    return items
  }
  
}
