/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
/**
 * Viser jordstykker for en ejendom
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.Datafordeler.HusnumreForJordstykke
 *   _options:
 *     fetcher:
 *       _type: Septima.Search.Datafordeler.Fetcher
 *       _options:
 *         ...
 * @sspath Septima.Search.Datafordeler
 * **/
export default class JordstykkerForEjendom extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} options.geosearcher Septima.Search.GeoSearch
   **/
  constructor(options={}) {

    let defaultOptions = {
      buttonText: "Jordstykker"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      let source = result.source
      let typeId = result.typeId
      return (source === "DAF" && typeId === "sfe")
    }

    if (typeof options.geosearcher !== 'undefined')
      this.geoSearcher = options.geosearcher
  }

  async myHandler(result, logger) {
    let source = result.source
    let typeId = result.typeId
    if (source === "DAF") {
      if (typeId === "sfe")
        return await this.getJordstykkeDetails(result, logger)
    }
  }
  
  async getJordstykkeDetails(bfeResult) {
    let bferesponse = bfeResult.data.bfeResponse
    
    let detailItemsList
    if (this.geoSearcher) {
      detailItemsList = new DetailItemsList({
        itemType: "result",
        name: "jordstykke_list"
      })

      let matIds = []

      for (let jordstykke of bferesponse.properties.jordstykke) {
        let matId = jordstykke.properties.ejerlavskode + "-" + jordstykke.properties.matrikelnummer
        matIds.push(matId)
        //TBD: this.geoSearcher.get(s)?
      }
      let matResults = await this.geoSearcher.gets(matIds, "matrikelnumre")
      for (let matResult of matResults) {
        detailItemsList.append({
          type: "result",
          result: matResult
        })
      }

    } else {
      detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        name: "jordstykke_list"
      })

      for (let jordstykke of bferesponse.properties.jordstykke) {
        detailItemsList.append({
          type: "labelvalue",
          value: jordstykke.properties.matrikelnummer + ", " + jordstykke.properties.ejerlavsnavn
        })
      }
    }

    return [detailItemsList.asItem()]

  }
}