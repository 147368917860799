/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"

/**
 * Viser boliger for et husnummer
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.Datafordeler.BoligerForHusnummerProvider
 *   _options:
 *     fetcher:
 *       _type: Septima.Search.Datafordeler.Fetcher
 *       _options:
 *         ...
 * @sspath Septima.Search.Datafordeler
 * **/
export default class BoligerForHusnummerProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.buttonText=Boliger på husnummeret] Titel
   * @param {Object} options.fetcher Septima.Search.Datafordeler.Fetcher instance
   **/
  constructor(options) {

    if (!options.fetcher)
      throw "BoligerForHusnummerProvider must be created with options.fetcher"

    let defaultOptions = {
      buttonText: "Boliger på husnummeret"
    }
    super(Object.assign(defaultOptions, options))


    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return (result.source === "Dawa" && result.typeId === "adresse")
    }
    this.fetcher = options.fetcher
  }

  async myHandler(dawaResult) {
    let detailItemsList = new DetailItemsList({
      header: "Boliger på husnummeret",
      name: "boliger_antal"
    })
    let items = []
    let husnummerId = dawaResult.data.properties.id
    let darHusnumreResponse = await this.fetcher.read("dar", "dar", "husnummer", {id: husnummerId}, this.getLogger())

    if (darHusnumreResponse.length > 0) {
      let bygnigsId = darHusnumreResponse[0].adgangTilBygning
      if (bygnigsId) {
        let bbrBygningsResponse = await this.fetcher.read("bbr", "bbr", "bygning", {id: bygnigsId}, this.getLogger())
        if(bbrBygningsResponse && bbrBygningsResponse.length > 0 && bbrBygningsResponse[0].opgangList && bbrBygningsResponse[0].opgangList.length > 0) {
          let opgangId = bbrBygningsResponse[0].opgangList.filter(opgangEntry => opgangEntry.opgang.adgangFraHusnummer === husnummerId)
          if (opgangId.length === 1) {
            let bbrEnhedsResponse = await this.fetcher.read("bbr", "bbr", "enhed", {opgang: opgangId[0].id_lokalId}, this.getLogger())
            if (bbrEnhedsResponse) {
              let relevanteEnheder =  bbrEnhedsResponse.filter(enhed => enhed.enh020EnhedensAnvendelse <= 200)
              let antalRelevanteEnheder = relevanteEnheder.length
              detailItemsList.append({
                type: "labelvalue",
                label: "Boliger",
                value: antalRelevanteEnheder
              })
              items.push(detailItemsList.asItem())
            }
          }
        }
      }
    }
    
    if (items.length > 0) {
      return items
    } else {
      detailItemsList.append({
        type: "labelvalue",
        value: "Ingen boliger på husnummeret"
      })
      items.push(detailItemsList.asItem())
      return items
    }
  }
}