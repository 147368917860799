/**
 * @module
 **/

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"

/**
 * Viser info for eet stednavn fra GeoStednavnSearcher
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.GeoStednavnSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.InfoForGeoStednavnProvider
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 *GeoStednavnSearcher.addDetailHandlerDef(new Septima.Search.InfoForGeoStednavnProvider())
 *
 * @example <caption>ES6:</caption>
 * import InfoForGeoStednavnProvider from './searchers/detailhandlers/InfoForGeoStednavnProvider.js'
 * GeoStednavnSearcher.addDetailHandlerDef(new InfoForGeoStednavnProvider())
 * @sspath Septima.Search
 **/
export default class InfoForGeoStednavnProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields=["hovedtype","undertype", "primærtnavn","primærnavnestatus","indbyggerantal","bebyggelseskode","ændret","geo_ændret","geo_version","visueltcenter_x","visueltcenter_y","brofast"] Felter, der skal medtages i info
   *
   **/
  constructor(options={}) {
    let defaultOptions = {
      buttonText: "Om stednavnet",
      more: true
    }
    super(Object.assign(defaultOptions, options))

    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.handler

    //Default fields
    this.fields = ["hovedtype","undertype", "primærtnavn","primærnavnestatus","indbyggerantal","bebyggelseskode","ændret","geo_ændret","geo_version","visueltcenter_x","visueltcenter_y","brofast"]

    if (typeof options.fields !== 'undefined')
      this.fields = options.fields
  }

  isApplicable(result) {
    return (result.source === "Kortforsyningen" && (result.type.id === "stednavne"))
  }

  async handler(result) {
    let items = []
    for (let field of this.fields) {
      let item = this.createItem(result, field)
      if (typeof item !== 'undefined')
        items.push(item)
    }
    return items
  }

  createItem(result, field) {

    let props = result.data.properties
    if (field === 'hovedtype')
      return {
        type: 'labelvalue',
        label: 'Hovedtype',
        value: props.hovedtype || 'Ikke angivet'
      }
    else if(field === 'undertype')
      return {
        type: 'labelvalue',
        label: 'Undertype',
        value: props.undertype || 'Ikke angivet'
      }

    else if(field === 'primærtnavn')
      return {
        type: 'labelvalue',
        label: 'Primært navn',
        value: props.primærtnavn || 'Ikke angivet'
      }
    else if(field === 'primærnavnestatus')
      return {
        type: 'labelvalue',
        label: 'Primær status',
        value: props.primærnavnestatus || 'Ikke angivet'
      }
    else if(field === 'indbyggerantal')
      return {
        type: 'labelvalue',
        label: 'Indbyggertal',
        value: props.indbyggerantal || 'Ikke angivet'
      }
    else if(field === 'bebyggelseskode')
      return {
        type: 'labelvalue',
        label: 'Bebyggelseskode',
        value: props.bebyggelseskode || 'Ikke angivet'
      }
    else if(field === 'ændret')
      return {
        type: 'labelvalue',
        label: 'Ændret',
        value: props.ændret || 'Ikke angivet'
      }
    else if(field === 'geo_ændret')
      return {
        type: 'labelvalue',
        label: 'Geografi ændret',
        value: props.geo_ændret || 'Ikke angivet'
      }
    else if(field === 'visueltcenter_x')
      return {
        type: 'labelvalue',
        label: 'Visuelt x',
        value: props.visueltcenter_x || 'Ikke angivet'
      }
    else if(field === 'visueltcenter_y')
      return {
        type: 'labelvalue',
        label: 'Visuelt y',
        value: props.visueltcenter_y || 'Ikke angivet'
      }
    else if(field === 'brofast')
      return {
        type: 'labelvalue',
        label: 'Brofast',
        value: props.brofast || 'Ikke angivet'
      }


    return
  }


}