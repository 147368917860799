/**
 * @module
 */
import Searcher from '../Searcher.js'
import ResultType from "../../ResultType.js"
import icons from "../../resources/icons.js"

export default class S4AddressSearcher extends Searcher {
  constructor(options = {}) {
    let defaultOptions = {
      usesGeoFunctions: true
    }
    super(Object.assign({}, defaultOptions, options))
    this.source = "s4"
    if (options.source)
      this.source = options.source

    this.s4Epsg = "25832"
    if (options.epsg)
      this.s4Epsg = options.epsg

    if (options.host) {
      this.host = options.host
      /*
      this.streetNameType = new ResultType(
        {
          id: "streetname",
          singular: "Vägnamn",
          plural: "Vägnamn",
          queryBehaviour: "none"
        })
      this.registerType(this.source, this.streetNameType)
       */
      this.addressType = new ResultType(
        {
          id: "adress",
          singular: "adress",
          plural: "adresser",
          queryBehaviour: "search"
        })
      this.registerType(this.source, this.addressType)
    } else {
      throw "SpandSearcher needs options.host"
    }
  }

  async asyncFetchData(query) {
    let data = {
      limit: query.limit + 1
    }

    if (query.queryString == "")
      data.query = "a"
    else
      data.query = query.queryString

    let url = this.host +  "/jsp/modules/adrsearch/query.jsp"

    let response = await this.fetch(url, {data})
    response.query = query

    return this.getDataSearchResult(response)
  }
  
  async get(id) {
    // https://kartan.stenungsund.se/jsp/modules/adrsearch/get.jsp?id=90000370
    let data = {
      id: id
    }
    let url = this.host +  "/jsp/modules/adrsearch/get.jsp"
    let response = await this.fetch(url, {data})
    if (response.numHits == 1) {
      let thisHit = response.data[0]
      let queryResult = this.createQueryResult()
      let niceStreetName = this.capitalize(thisHit.streetName)
      let niceDistrictName = this.capitalize(thisHit.districtName)
      let presentationString = niceStreetName + " " + thisHit.streetBuildingIdentifier + ", "  + thisHit.postCodeIdentifier + ' ' + niceDistrictName
      let resultGeometry = this.translateWktToGeoJsonObject(thisHit.geometryWkt, this.s4Epsg)
      let result = queryResult.addResult(this.source, this.addressType.id, presentationString, null, resultGeometry, thisHit)
      result.id = thisHit.properties.addressaccessid
      result.image = icons.mapPointGrey
      return result
    } else {
      return null
    }
  }

  getDataSearchResult (data) {
    let queryResult = this.createQueryResult()
    let query = data.query
    let limit = query.limit
    let hitType
    let result
    let newQuery
    if (data.numHits > 0 ) {
      hitType = data.data[0].type
    }
    for (let i = 0 ; i < data.numHits && i < limit; i++) {
      let thisHit = data.data[i]
      if (hitType == 'streetNameType') {
        let niceStreetName = this.capitalize(thisHit.streetName)
        let niceDistrictName = this.capitalize(thisHit.districtName)
        
        let presentationString = niceStreetName + " "  + thisHit.postCodeIdentifier + ' ' + niceDistrictName
        let newQueryString = niceStreetName + " <select></select> "  + thisHit.postCodeIdentifier + ' ' + niceDistrictName
        
        newQuery = queryResult.addNewQuery(this.source, this.addressType.id, presentationString, null, newQueryString, null, thisHit)
        newQuery.image = icons.road
      }else{
        let niceStreetName = this.capitalize(thisHit.streetName)
        let niceDistrictName = this.capitalize(thisHit.districtName)
        let presentationString = niceStreetName + " " + thisHit.streetBuildingIdentifier + ", "  + thisHit.postCodeIdentifier + ' ' + niceDistrictName
        let resultGeometry = this.translateWktToGeoJsonObject(thisHit.geometryWkt, this.s4Epsg)
        result = queryResult.addResult(this.source, this.addressType.id, presentationString, null, resultGeometry, thisHit)
        result.id = thisHit.properties.addressaccessid
        result.image = icons.mapPointGrey
      }
    }
    if (hitType == 'streetNameType' && data.numHits > limit && !query.hasTarget) {
      let description = null
      newQuery = queryResult.addNewQuery(this.source, this.addressType.id, "Fler Adresser", description, query.queryString, null, null)
      newQuery.image = icons.road
    }
    if (hitType == 'addressAccessType' && data.numHits > limit && !query.hasTarget) {
      let description = null
      if (query.queryString.length > 0) {
        description = "Fler adresser " + this.getMatchesPhrase() +" <em>" + query.queryString + "</em>"
      }
      result = queryResult.addNewQuery(this.source, this.addressType.id, "Fler Adresser", description, query.queryString, null, null)
      result.image = icons.mapPointGrey
    }

    return queryResult
  }
  
  capitalize(string) {
    try {
      const words = string.split(" ")
      let capitalizedWords = words.map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase()
      })
      let capitalizedString = capitalizedWords.join(" ")
      return capitalizedString
    } catch (e) {
      return string
    }
  }
}