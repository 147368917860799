/**
 * @module
 */
import Searcher from "../Searcher.js"
import jsonpath from "../../util/JsonParser.js"
import * as reproject from "../../util/reproject.js"
import ResultType from "../../ResultType.js"
import AbeDetails from "../../details/Details.js"
/**
 * BETA BETA Søger features i geoserver BETA BETA
 * @extends module:js/searchers/Searcher
 *
 * @example <caption>YAML Declaration</caption>
mysearchers:
  daiservice:
    _type: Septima.Search.Geoserver.Service
    _options:
      endpoint: https://b0902-udv-dist-app.azurewebsites.net/geoserver/wfs
      source: dai
      defaultIdField: {name: "Objekt_id", type: "string"}
      defaultFields: {geometry: "Shape"} 
  dai:bes_vandloeb:
    _type: Septima.Search.Geoserver.Searcher
    _options:
      service:
        _ref: $.mysearchers.daiservice
      featureType: {name: "dai:bes_vandloeb", singular: "Beskyttet vandløb", plural: "Beskyttede vandløb"}
  dai:soe_bes_linjer:
    _type: Septima.Search.Geoserver.Searcher
    _options:
      service:
        _ref: $.mysearchers.daiservice
      featureType: {name: "dai:soe_bes_linjer", singular: "Søbeskyttelseslinie", plural: "Søbeskyttelseslinier"}
 * @sspath Septima.Search
 */
export default class WfsSearcher extends Searcher {

  /**
   * @param {Object} options Searcher expects these options:
   * @param {string} options.service a Septima.Search.Geoserver.Service
   * @param {string} [options.source=service.source] Results will set to this source (overrides service.source)
   * @param {Object} options.featureType the featuretype beeing sought
   * @param {string} options.featureType.name name of the featuretype beeing sought
   * @param {string} options.featureType.singular singular denomination of type
   * @param {string} options.featureType.plural plural denomination of type
   * @param {Object} [options.idField] id information (overrides service.defaultIdField)
   * @param {string} [options.idField.name] the name of the field containing a feature's id
   * @param {string} [options.idField.type] the type of the field containing a feature's id
   * @param {Object} [options.fields] information on other important field names (overrides service.defaultFields)
   * @param {string} options.fields.geometry the name of the field containing the geometry
   * @param {string} [options.fields.title] the name of the field to be used as title
   * @param {string} [options.fields.description] the name of the field to be used as description
   * @param {Object} [options.details] Atteaches a Septima.Search.Details handler to the searcher - this options is the same as Septima.Search.Details -> options.details  
   * 
   */
  constructor(options={}) {
    
    let defaultOptions = {
      queryBehaviour: "match"
    }

    super(Object.assign(defaultOptions, options))
    
    if (!options.service)
      throw new Error("geoserver.Searcher needs option.service")
    else
      this.service = options.service
    
    if (!options.featureType) {
      throw new Error("geoserver.Searcher needs option.featureType")
    } else {
      if (typeof options.featureType == "string") {
        this.featureType = {
          name: options.featureType,
          singular: options.featureType,
          plural: options.featureType
        }
      } else {
        if (typeof options.featureType == "object" && options.featureType.name) {
          this.featureType = options.featureType
          if (!options.featureType.singular)
            options.featureType.singular = options.featureType.name
          if (!options.featureType.plural)
            options.featureType.plural = options.featureType.singular
        } else {
          throw new Error("GenericWfsSearcher needs option.featureType.name")
        }
      }
    }

    this.source = this.service.source
    if (options.source)
      this.source = options.source

    if (options.fields)
      this.fields = options.fields
    else {
      if (this.service.defaultFields)
        this.fields = this.service.defaultFields
      else
        throw new Error("geoserver.Searcher needs option.fields or a geoserver.Service with defaultFields")
    } 

    if (options.idField)
      this.idField = options.idField
    else {
      if (this.service.defaultIdField)
        this.idField = this.service.defaultIdField
      else  
        throw new Error("geoserver.Searcher needs option.idField or a geoserver.Service with defaultIdField")
    }

    this.details = []
    if (options.details)
      this.details = options.details
    
    let myType = new ResultType({
      id: this.featureType.name,
      singular: this.featureType.singular,
      plural: this.featureType.plural,
      queryBehaviour: this.queryBehaviour,
      geometrySupport: "sq"
    })
    this.registerType(this.source, myType)
  }
  
  async asyncFetchData(query) {
    if (this.queryBehaviour == "search" || this.queryBehaviour == "match") {
      let promises = []
      //By Id
      if (!query.isBlank) {
        promises.push(this.service.fetchFeatureById(this.featureType.name, query.queryString, this.idField.name))
      }
      if (this.queryBehaviour == "search") {
        for (let queryField of this.queryFields)
          promises.push(this.service.fetchFeatures(`${queryField} like %27${query.queryString}%25%27`, {limit: query.limit}))
      }
      await Promise.all(promises)
      let features = []
      for (let promise of promises)
        features = features.concat(await promise)
      return this.createQueryResultFromFeatures(features)
    } else {
      return this.createQueryResult()
    }
  }

  async get(id) {
    let features = await this.service.fetchFeatureById(this.featureType.name, id)
    let queryResult = this.createQueryResultFromFeatures(features)
    let results = queryResult.getResults()
    if (results.length === 1)
      return results[0]
  }

  async sq(query) {
    let queryGeometry = reproject.reproject(query.geometry, null, "EPSG:25832")
    let features = await this.service.fetchFeatureByGeometry(this.featureType.name, queryGeometry, this.fields.geometry, {limit: query.limit})
    return this.createQueryResultFromFeatures(features)
  }

  createQueryResultFromFeatures(features) {
    let queryResult = this.createQueryResult()
    for (let feature of features)
      this.addFeatureToQueryResult(feature, queryResult)
    return queryResult
  }

  addFeatureToQueryResult(feature, queryResult) {
    let title = (this.fields.title) ? jsonpath.value(feature, "$.properties." + this.fields.title) : this.featureType.singular
    let description = (this.fields.description) ? jsonpath.value(feature, "$.properties." + this.fields.description) : null
    let geometry = feature.geometry
    geometry.crs = {
      "type": "name",
      "properties": {
        "name": 'epsg:25832'
      }
    }
    let result = queryResult.addResult(this.source, this.featureType.name, title, description, geometry, feature)
    result.id = jsonpath.value(feature, "$.properties." + this.idField.name)
  }

  getDetailHandlersForResult() {
    if (this.details.length > 0) {
      let detailsHandler = new AbeDetails({
        id: this.featureType.name + "_details",
        details: this.details,
        more: true
      })
      return [detailsHandler]
    }
    return []
  }

}
  
