/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"

/**
 * viser husnummeret for en adresse
 * @sspath Septima.Search
 */
export default class HusnummerForAdresseProvider extends DetailsHandlerDef {
  constructor(options= {}) {

    let defaultOptions = {
      buttonText: "Husnummer for adressen",
      targets: [{source: "Dawa", typeId: "enhedsadresse"}]
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
  }
  async myHandler(result) {
    let husnummerId = result.data.properties.adgangsadresse.id
    let husnummerResult = await result.searcher.get(husnummerId, "adresse")
    return [{
      type: "result",
      label: "Husnummer",
      result: husnummerResult
    }]
  }
}