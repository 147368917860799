import icons from "../../../resources/icons.js"
import DetailsHandlerDef from "../../../details/DetailsHandlerDef.js"

export default class StaticDetailsProvider extends DetailsHandlerDef {
  constructor(options={}) {
    if (options.logger)
      options.logger.warn("StaticDetailsProvider er deprecated. Brug Septima.Search.Details istedet (https://onedoor.test.septima.dk/guide/Septima.Search.Details.html)")
    else
      try {
        // eslint-disable-next-line no-console
        console.warn("StaticDetailsProvider er deprecated. Brug Septima.Search.Details istedet (https://onedoor.test.septima.dk/guide/Septima.Search.Details.html)")
        // eslint-disable-next-line no-empty
      }catch(e) {}
    
    let defaultOptions = {
      buttonText: "Detaljer",
      buttonImage: icons.infoGrey 
    }
    super(Object.assign(defaultOptions, options))
    
    this.content = []
    
    if (options.content)
      this.content = options.content

    this.handlerFunction = this.myHandler
  } 
  
  // eslint-disable-next-line no-unused-vars
  async myHandler(result) {
    return this.content
  }
}