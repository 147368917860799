/**
 * @module
 */
import icons from '../resources/icons.js'
import {API} from '../util/dawa.api.js'
import Searcher from './Searcher.js'
import ResultType from "../ResultType.js"
import * as reproject from "../util/reproject.js"
import {getWKTParser} from "../util/getWKTParser.js"
import Cache from "../util/Cache.js"

/**
 *
 * Searches Dawa (http://dawa.aws.dk/)
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
  _type: Septima.Search.DawaSearcher
  _options:
    kommunekode: 151
 * @example <caption> JS options:</caption>
 * dawaSearchOptions = {
 *     kommunekode: "151",
 *     searchDelay: 200
 * };
 * @example <caption>js client:</caption>
 * <!-- Include septimaSearch -->
 * <!-- Include septimaSearch -->
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.DawaSearcher(dawaSearchOptions))
 *
 * @example <caption>ES6:</caption>
 * import DawaSearcher from './searchers/DawaSearcher'
 * controller.addSearcher(new DawaSearcher(dawaSearchOptions))
 * @api
 */
export default class DawaSearcher extends Searcher {
  /**
   *
   * @param {Object} options DawaSearcher expects these properties:
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {boolean} [options.showOneStreet=false]
   * @param {string} [options.source='Dawa']
   * @param {string} [options.goal='adgangsadresse'] Maybe 'adgangsadresse', adgangellerenhedsadresse, or "enhedsadresse"
   */

  constructor(options = {}) {
    super(Object.assign({
      usesGeoFunctions: true,
      iconURI:icons.searchers.dawa.result
    },
    options))

    //defaults    
    this.goal = "adgangsadresse"
    if (options.goal) {
      if (options.goal == "adgangellerenhedsadresse")
        this.goal = "adgangellerenhedsadresse"
      else if (options.goal == "enhedsadresse")
        this.goal = "enhedsadresse"
    }

    this.kommunekode = "*"
    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += '' 
      let kommunekoder = options.kommunekode.split(' ')
      this.kommunekode = kommunekoder.join('|')
    }

    this.showOneStreet = false
    if (options.showOneStreet) 
      this.showOneStreet = options.showOneStreet

    this.source = "Dawa"
    if (options.source) 
      this.source = options.source

    let vejType = new ResultType({
      id: "vej",
      singular: "Vej",
      plural: "Veje",
      queryBehaviour: "none",
      iconURI: icons.searchers.dawa.road
    })
    this.registerType(this.source, vejType)
    
    let husnummerType = new ResultType({
      id: "adresse",
      singular: "Husnummer",
      plural: "Husnumre",
      iconURI: this.goal == "adgangsadresse" ? icons.mapPointGrey : icons.searchers.dawa.house
    })
    this.registerType(this.source, husnummerType)

    let adresseType = new ResultType({
      id: "enhedsadresse",
      singular: "Adresse",
      plural: "Adresser",
      queryBehaviour: "none",
      iconURI: icons.searchers.dawa.unit
    })
    this.registerType(this.source, adresseType)

    let apiOptions = {}
    if (this.kommunekode !== "*")
      apiOptions.kommunekode = this.kommunekode

    this.api = new API(apiOptions)
    this.adresseCache = new Cache({ttl: 1000, maxEntries: 40})
  }

  async fetchData(query, caller) {
    let goal = this.goal
    const queryOptions = {
      limit: query.limit + 3,
      query: query.queryString == "" ? "a" : query.queryString,
      goal: goal,
      showStreets: this.showOneStreet
    }
    try {
      let dawaHits = await this.api.query(queryOptions)
      let queryResult = this.QueryResultFromDawaResult(query, dawaHits, goal) 
      caller.fetchSuccess(queryResult)
    } catch (error) {
      let logger = this.getLogger()
      if (logger)
        logger.error("Error in DawaSearcher.fetchData: " + error)
      caller.fetchError(this, error)
    }
  }

  QueryResultFromDawaResult(query, dawaHits, goal) {
    const queryResult = this.createQueryResult()
    const count = dawaHits.length
    let plural = this.getType(this.source, "adresse").plural

    if (["list", "cut", "no-cut"].indexOf(query.type) !== -1) {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let thisHit of dawaHits.slice(0, hitsShown))
        this.addHitToQueryResult(thisHit, queryResult, goal)
      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
        if (hitsShown > 0)
          queryResult.addNewQuery(this.source, "adresse", plural, "", query.queryString, null, null)
        else
          queryResult.addNewQuery(this.source, "adresse", plural, "", query.queryString, null, null)
      }
    }else{
      if (count > 0) {
        queryResult.addNewQuery(this.source, "adresse", plural, "", query.queryString, null, null)
      }
    }

    return queryResult
  }

  addHitToQueryResult(thisHit, queryResult, goal) {
    let newQueryString
    let newQuery
    if (thisHit.type === "enhedsadresseinfo") {
      let title = thisHit.tekst
      let result = queryResult.addResult(this.source, "enhedsadresse", title, null, thisHit.geometry, thisHit)
      result.id = thisHit.id
      result.isComplete = false
    }
    
    if (thisHit.type === "lonelyadgangsadresseinfo") {
      let title = thisHit.tekst
      let result = queryResult.addResult(this.source, "adresse", title, null, thisHit.geometry, thisHit)
      result.id = thisHit.id
      result.isComplete = false
    }
    
    if (thisHit.type === "adgangsadresseinfo") {
      if (goal == "adgangsadresse") {
        let title = thisHit.tekst
        let result = queryResult.addResult(this.source, "adresse", title, null, thisHit.geometry, thisHit) //thisHit har IKKE matinfo
        result.isComplete = false
        result.id = thisHit.id
      }
      if (goal == "adgangellerenhedsadresse") {
        let title = thisHit.tekst
        if (thisHit.enhedsAdresser && thisHit.enhedsAdresser.length > 0) {
          newQuery = queryResult.addNewQuery(this.source, "adresse", title, null, title, null, thisHit)
          newQuery.image = icons.searchers.dawa.housewithunits
        } else {
          let result = queryResult.addResult(this.source, "adresse", title, null, thisHit.geometry, thisHit)
          result.isComplete = false
          result.id = thisHit.id
        }
      }
      if (goal == "enhedsadresse") {
        let title = thisHit.tekst
        if (thisHit.enhedsAdresser && thisHit.enhedsAdresser.length > 0) {
          newQuery = queryResult.addNewQuery(this.source, "adresse", title, null, title, null, thisHit)
          newQuery.image = icons.searchers.dawa.housewithunits
        }
      }
    }
    if (thisHit.type === "vejnavnpostnummer") {
      let presentation
      newQueryString = thisHit.vejnavn + " <select></select>" + ' ' + thisHit.postnummer + ' ' + thisHit.postdistrikt
      presentation = thisHit.vejnavn + ' ' + thisHit.postnummer + ' ' + thisHit.postdistrikt
      newQuery = queryResult.addNewQuery(this.source, "adresse", presentation, null, newQueryString, null, thisHit)
      newQuery.image = icons.searchers.dawa.road
    }
    if (thisHit.type === "vejnavn") {
      let presentation
      newQueryString = thisHit.vejnavn + " <select></select>"
      presentation = thisHit.vejnavn
      newQuery = queryResult.addNewQuery(this.source, "adresse", presentation, null, newQueryString, null, thisHit)
      newQuery.image = icons.searchers.dawa.road
    }
    if (thisHit.type === "vej") {
      let presentation = thisHit.vejnavn + ' ' + thisHit.postnummer + ' ' + thisHit.postdistrikt
      let result = queryResult.addResult(this.source, "vej", presentation, null, thisHit.geometry, thisHit.feature)
      result.id = thisHit.id
    }
    if (thisHit.type === "vejstykke") {
      //Postnumre, med flere som del af navnet
      let result = queryResult.addResult(this.source, "vej", thisHit.properties.adresseringsnavn, null, thisHit.geometry, thisHit)
      result.id = thisHit.properties.id
    }
  }

  async get(id, typeId) {
    if (typeId === 'adresse') {
      let cachedResult = this.adresseCache.get(id)
      if (cachedResult)
        return cachedResult
      let queryResult = this.createQueryResult()
      //let data = await new AdgangsAdresseGetter().get(id)
      let dawaHit = await this.api.get(id, 'adgangsadresse')
      let result = queryResult.addResult(this.source, "adresse", dawaHit.properties.vejstykke.navn + " " + dawaHit.properties.husnr + ", " + dawaHit.properties.postnummer.nr + " " + dawaHit.properties.postnummer.navn, null, dawaHit.geometry, dawaHit)
      result.id = id
      result.isComplete = true
      result.data.type = 'adgangsadresse'
      this.adresseCache.setResult(result)
      return result
    } else if (typeId === 'enhedsadresse') {
      let queryResult = this.createQueryResult()
      //let data = await new EnhedsAdresseGetter().get(id)
      let dawaHit = await this.api.get(id, 'enhedsadresse')
      let result = queryResult.addResult(this.source, "enhedsadresse", dawaHit.properties.adressebetegnelse, null, dawaHit.geometry, dawaHit)
      result.id = id
      result.isComplete = true
      result.data.type = 'enhedsadresse'
      return result
    } else if (typeId === 'vej') {
      let queryResult = this.createQueryResult()
      let thisHit = await this.api.get(id, 'vej')
      let presentation = thisHit.vejnavn + ' ' + thisHit.postnummer + ' ' + thisHit.postdistrikt
      let result = queryResult.addResult(this.source, "vej", presentation, null, thisHit.geometry, thisHit.feature)
      result.id = id
      return result
    }else {
      return null
    }
  }


  async sq(query) {
    let queryResult = this.createQueryResult()
    if (query.geometry) {
      let queryGeometry = reproject.reproject(query.geometry, null, "EPSG:25832")
      let wktParser = getWKTParser()
      let querywkt = wktParser.convert(queryGeometry)
      if (querywkt && querywkt.toLowerCase().indexOf("polygon") === 0) {
        //let features = await new AdgangsAdresseGetter().sq(querywkt)
        let features = await this.api.adgangsAdresseAPI.sq(querywkt)
        const sorter = function(f1, f2) {
          try {
            const n1 = f1.properties.vejstykke.navn
            const n2 = f2.properties.vejstykke.navn
            if (n1 === n2) {
              const h1 = parseInt(f1.properties.husnr)
              const h2 = parseInt(f2.properties.husnr)
              if (h1 === h2)
                return (f1.properties.husnr.localeCompare(f2.properties.husnr))
              else
                return (h1 - h2)
            } else {
              return (n1.localeCompare(n2))
            }

          } catch (error) {
            return 0
          }
        }
        features = features.sort(sorter)

        for (let feature of features) {
          let result = queryResult.addResult(this.source, "adresse", feature.properties.vejstykke.navn + " " + feature.properties.husnr + ", " + feature.properties.postnummer.nr + " " + feature.properties.postnummer.navn, null, feature.geometry, feature)
          result.id = feature.properties.id
          result.isComplete = true
          result.data.type = 'adgangsadresse'
        }
      }
    }
    
    return queryResult
  }

  error(caller, jqXHR, textStatus, errorThrown) {
    caller.fetchError(this, errorThrown)
  }

  async getEnhedsAdresserForAdresse(adresseId) {
    //let data = await new EnhedsAdresseGetter().getEnhedsAdresserForAdresse(adresseId)
    let data = await this.api.getEnhedsAdresserForAdresse(adresseId)
    return data
  }
  
  async gets(ids, typeId) {
    let results = []
    if (typeId === 'adresse') {
      let queryResult = this.createQueryResult()
      //let featureCollection = await new AdgangsAdresseGetter().gets(ids)
      let featureCollection = await this.api.gets(ids, 'adgangsadresse')
      for (let feature of featureCollection.features) {
        let result = queryResult.addResult(this.source, "adresse", feature.properties.vejstykke.navn + " " + feature.properties.husnr + ", " + feature.properties.postnummer.nr + " " + feature.properties.postnummer.navn, null, feature.geometry, feature)
        result.id = feature.properties.id
        result.isComplete = true
        result.data.type = 'adgangsadresse'
        results.push(result)
      }
    } else if (typeId === 'enhedsadresse') {
      let queryResult = this.createQueryResult()
      let featureCollection = await this.api.gets(ids, "enhedsadresse")
      for (let feature of featureCollection.features) {
        let result = queryResult.addResult(this.source, "enhedsadresse", feature.properties.adressebetegnelse, null, feature.geometry, feature)
        result.id = feature.properties.id
        result.isComplete = true
        result.data.type = 'enhedsadresse'
        results.push(result)
      }
    }
    return results
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      if (result.typeId == 'adresse') {
        let hit = await this.api.get(result.data.id, "adgangsadresse")
        result.typeId = 'adresse'
        result.data.type = 'adgangsadresse'
        result.data = hit
        result.geometry = hit.geometry
        result.isComplete = true
        return result
      } else if (result.typeId == 'enhedsadresse') {
        let hit = await this.api.get(result.data.id, "enhedsadresse")
        result.typeId = 'enhedsadresse'
        result.data.type = 'enhedsadresse'
        result.data = hit
        result.geometry = hit.geometry
        result.isComplete = true
        return result
      }
    }
  }
  
  async getAdresserForHusnummer(husnummerId) {
    let data = await this.api.getEnhedsAdresserForAdresse(husnummerId)
    return data
  }
  
  async getHusnumreForVej(vejResult) {
    let kommunekode = vejResult.data.properties.kommune.kode
    let vejkode = vejResult.data.properties.kode
    let dawaHits = await this.api.getHusnumreForVej(vejkode, kommunekode)
    let queryResult = this.createQueryResult()
    for (let dawaHit of dawaHits) {
      let result = queryResult.addResult(this.source, "adresse", dawaHit.properties.vejstykke.navn + " " + dawaHit.properties.husnr + ", " + dawaHit.properties.postnummer.nr + " " + dawaHit.properties.postnummer.navn, null, dawaHit.geometry, dawaHit)
      result.id = dawaHit.properties.id
      result.isComplete = true
      result.data.type = 'adgangsadresse'
    }
    return queryResult.getResults()
  }


  async onSelect(result) {
    //if (typeof result.newquery !== 'undefined') {
    if (result.isNewQuery()) {
      if (result.data && result.data.type)
        if (result.data.type === 'vejnavnpostnummer') {
          let queryResult = this.createQueryResult()
          let thisHit = await this.api.getVejForNavnPostnummer(result.data.vejnavn, result.data.postnummer)
          let presentation = thisHit.vejnavn + ' ' + thisHit.postnummer + ' ' + thisHit.postdistrikt
          let fakeResult = queryResult.addResult(this.source, "vej", presentation, null, thisHit.geometry, thisHit.feature)
          fakeResult.id = thisHit.id
          fakeResult.image = icons.searchers.dawa.road
          this._onSelectCallback(fakeResult)
        }
    } else {
      this._onSelectCallback(result)
    }
  }
  
  

}
