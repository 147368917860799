/**
 * @module
 */
import Searcher from '../Searcher.js'
import Icons from "../../resources/icons.js"
import ResultType from "../../ResultType.js"
import * as reproject from "../../util/reproject.js"
import GSearchFetcher from "./GSearchFetcher.js"

/**
 *
 * Searches gst geoservice for stednavne (https://docs.dataforsyningen.dk/#gsearch-dokumentation)
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
      _type: Septima.Search.GeoStednavnSearch
      _options:
        authParams:
          login: xxxx
          password: xxxx
        kommunekode: '101 157'


 * @example <caption> JS options:</caption>
 * geoStednavnSearchOptions = {
 *     authParams: {
 *      login: 'xxxx',
 *      password: 'xxxxxx'
 *     }
 * };
 * 
 * @example <caption>js client:</caption>
 * <!-- Include septimaSearch -->
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.GeoStednavnSearch(geoStednavnSearchOptions))
 *
 * @example <caption>ES6:</caption>
 * import GeoStednavnSearch from './searchers/GeoStednavnSearch.js'
 * controller.addSearcher(new GeoStednavnSearch(geoStednavnSearchOptions))
 * @sspath Septima.Search
 */

export default class GeoStednavnSearcher extends Searcher {
  /**
   * @param {Object} options GeoSearch expects these properties:
   * @param {String} [options.kommunekode=*] "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param  {Object} [options.authParams] Either {ticket: 'ttttt'}, where t is a ticket issued by kortforsyningen or {login: 'lll', password: 'pppp'}
   *
   */
  constructor(options= {}) {

    super(Object.assign({
      usesGeoFunctions: true,
      defaultCrs: "25832",
      iconURI: Icons.searchers.geoSearch.result
    },
    options))

    this.options = options
    this.serviceUrl =  'https://api.dataforsyningen.dk'

    if (!options.source) 
      options.source = "Kortforsyningen"
    
    this.source = options.source
    
    this.resultType = new ResultType({
      id: "stednavne",
      singular: "Stednavn",
      plural: "Stednavne"
    })
    this.registerType(this.source,  this.resultType)
    this.area = null
    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += ''
      let municipalities = options.kommunekode.split(' ')
      for (let i=0; i<municipalities.length; i++) 
        municipalities[i] = "muncode0" + municipalities[i]
      
      this.area = municipalities.join()
    }else if (options.area) {
      this.area = options.area
    }
    this.authParams = {
      token: '22e4c7f57b6172b780abf7a447ebe7bd'
    }
    this.visUOfficiel = false
    if (options.visUOfficiel) 
      this.visUOfficiel = options.visUOfficiel

    reproject.registerCrs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")

    this.gSearchFetcher = new GSearchFetcher({kommunekode: options.kommunekode, authParamsDataforsyningen: this.authParamsKortforsyningen, convert2Geo: true, defaultTypes: ['stednavn']})
  }

  async fetchData(query, caller) {
    caller.fetchSuccess(await this.query(query))
  }

  async query(query) {
    let results = this.createQueryResult()
    let items
    try {
      items = await this.gSearchFetcher.fetchFromGSearch(query.queryString, Math.max(query.limit, 100) + 1, 'stednavn')
    } catch(error) {
      let logger = this.getLogger()
      if (logger)
        logger.error("Error in GeoStednavnSearcher.query: " + error)
      return results
    }

    if (typeof items.data === 'undefined') {
      return results
    }
    items = items.data
    let count = items.length
    
    if (query.type === 'collapse') {
      if (count > 0) {
        let title = this.resultType.plural
        if (!query.isBlank)
          if (count > Math.max(query.limit, 100))
            title += ` (${Math.max(query.limit, 100)}+)`
          else
            title += ` (${count})`
        results.addNewQuery(this.source, this.resultType.id, title, null, query.queryString, null, null)
      }
    }else {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let item of items.slice(0, hitsShown))
        this.addItemAsResult(results, item)
      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
        let title = this.resultType.plural
        if (!query.isBlank)
          if (count > Math.max(query.limit, 100))
            title += ` (${Math.max(query.limit, 100)}+)`
          else
            title += ` (${count})`
        results.addNewQuery(this.source, this.resultType.id, title, null, query.queryString, null, null)
      }
    }
    return results
  }

  removeDuplicates(items) {
    //https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/
    return items.filter((item, pos, arr) => {
      return arr.map(mapObj => mapObj.id_lokalid).indexOf(item.id_lokalid) === pos
    })
  }

  addItemAsResult(results, item) {
    let navn = item.visningstekst
    let description = null
    let resultGeometry = item.geometri
    let result = results.addResult(this.source, this.resultType.id, navn, description, resultGeometry)
    result.id = item.id
    result.isComplete = false
  }
  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      result.isComplete = true
      let result2 = await this.get(result.id)
      return result2
    }
  }
  
  async get (id) {
    let qr = this.createQueryResult()
    //https://dawa.aws.dk/steder/12337669-67fe-6b98-e053-d480220a5a3f?format=geojson
    let url = "https://api.dataforsyningen.dk/steder/" + id
    let params = {
      format: "geojson",
      srid: "25832"
    }
    let hit = await this.fetch(url, {data: params})
    let result = qr.addResult(this.source, this.resultType.id, hit.properties.primærtnavn, null, hit.geometry, hit)
    result.id = id
    return result
  }
}