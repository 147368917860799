/**
 * @module
 */
import LifaLinks from "../LifaLinks.js"
export default class EjdExplorerLinks extends LifaLinks {

  constructor(options={}) {
    if (options.logger)
      options.logger.warn("EjdExplorerLinks er deprecated. brug LifaLinks i stedet")
    else
      // eslint-disable-next-line no-console
      if (console && console.warn) {
        try{
        // eslint-disable-next-line no-console
          console.warn("EjdExplorerLinks er deprecated. brug LifaLinks i stedet")
        // eslint-disable-next-line no-empty
        } catch(e) {}
      }
    let defaultOptions = {
      links: ["ejdexplorer"]
    }
    super(Object.assign({},defaultOptions, options))
  }
}


