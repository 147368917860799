/**
 * @module
 */
import {fetch2} from "../../utils.js"

/**
 * 
 * @sspath Septima.Search.GeoFa
 */

export default class Fetcher {
  constructor() {
    this.endpoint = "https://fkg.mapcentia.com/api/v2/sql/fkg?"
  }

  async search(q) {
    let data =  {
      srs : "25832",
      q: q
    }
    
    let url = `${this.endpoint}`
    return await fetch2(url,  {data: data})
  }

  async get(target, params) {
    let url = `${this.endpoint}`
    return await fetch2(url, {data: params})
  }

}
