import CVR_enhedSearcher from "./searchers/CVR_enhedSearcher.js"
import DawaSearcher from "./searchers/DawaSearcher.js"
import DawaStednavnSearcher from "./searchers/DawaStednavnSearcher.js"
import GeoStednavnSearcher from "./searchers/geosearch/GeoStednavnSearcher.js"
import PlanSystemSearcher from "./searchers/PlanSystemSearcher.js"
import IsoChroneSearcher from "./searchers/IsoChroneSearcher.js"
import FkgSearcher from "./searchers/FkgSearcher.js"
import DokLinksForLokalplan from "./searchers/detailhandlers/DokLinksForLokalplan.js"
import LifaLinkForAdresseMatrikelHandler from "./searchers/detailhandlers/deprecated/LifaLinkForAdresseMatrikelHandler.js"
import EjdExplorerLinks from "./searchers/detailhandlers/deprecated/EjdExplorerLinks.js"

import JordForureningsattestLinkHandler from "./searchers/detailhandlers/deprecated/JordForureningsattestLinkHandler.js"
import CvrLinkHandler from "./searchers/detailhandlers/deprecated/CvrLinkHandler.js"
import CvrInfoProvider from "./searchers/detailhandlers/deprecated/CvrInfoProvider.js"
import RegnskaberForCvrNummerHandler from "./searchers/detailhandlers/RegnskaberForCvrNummerHandler.js"
import PlanInfoProvider from "./searchers/detailhandlers/PlanInfoProvider.js"
import FkgInfoProvider from "./searchers/detailhandlers/FkgInfoProvider.js"
import HusnummerInfoProvider from "./searchers/detailhandlers/HusnummerInfoProvider.js"
import OffentligeLinksForAdresseMatrikel from "./searchers/detailhandlers/OffentligeLinksForAdresseMatrikel.js"
import TjekDitNetForAdresseProvider from "./searchers/detailhandlers/TjekDitNetForAdresseProvider.js"
import ClassRegistry from "./ClassRegistry.js"
import InfoForKommuneProvider from "./searchers/detailhandlers/InfoForKommuneProvider.js"
import InfoForOpstillingsKredsProvider from "./searchers/detailhandlers/InfoForOpstillingskredsProvider.js"
import InfoForPolitidistriktProvider from "./searchers/detailhandlers/InfoForPolitidistriktProvider.js"
import InfoForPostdistriktProvider from "./searchers/detailhandlers/InfoForPostdistriktProvider.js"
import InfoForRetskredsProvider from "./searchers/detailhandlers/InfoForRetskredsProvider.js"
import InfoForSognProvider from "./searchers/detailhandlers/InfoForSognProvider.js"
import InfoForGeoStednavnProvider from "./searchers/detailhandlers/InfoForGeoStednavnProvider.js"
import CvrVirksomhederForAdresseProvider from "./searchers/detailhandlers/CvrVirksomhederForAdresseProvider.js"
import JordStykkeInfoProvider from "./searchers/detailhandlers/JordStykkeInfoProvider.js"
import DagiInfoProvider from "./searchers/detailhandlers/DagiInfoProvider.js"
import SkraafotoProvider from "./searchers/detailhandlers/SkraafotoProvider.js"
import AdresserForHusnummerProvider from "./searchers/detailhandlers/AdresserForHusnummerProvider.js"

import VidiLinkForGeometry from "./searchers/detailhandlers/VidiLinkForGeometry.js"
import VirksomhederMedBranchekodeRadiusforAdresse from "./searchers/detailhandlers/VirksomhederMedBranchekodeRadiusforAdresse.js"
import DistanceProvider from "./searchers/detailhandlers/DistanceProvider.js"
import RouteProvider from "./searchers/detailhandlers/RouteProvider.js"
import HusnummerForAdresseProvider from "./searchers/detailhandlers/HusnummerForAdresseProvider.js"
import FilarkivLinkForAdresseMatrikelHandler from "./searchers/detailhandlers/FilarkivLinkForAdresseMatrikelHandler.js"
import HusnumreForVejProvider from "./searchers/detailhandlers/HusnumreForVejProvider.js"
import OffentligeLinksProvider from "./searchers/detailhandlers/OffentligeLinksProvider.js"
import GSearch from "./searchers/geosearch/GSearch.js"
import GeoSearch from "./searchers/geosearch/GeoSearch.js"
import CowiGadefotoProvider from "./searchers/detailhandlers/CowiGadefotoProvider.js"
import LifaLinks from "./searchers/detailhandlers/LifaLinks.js"
const dkTypes = new ClassRegistry("Septima.Search.")

const typesToAdd = {
  DistanceProvider,
  RouteProvider,
  CVR_enhedSearcher,
  DawaSearcher,
  DawaStednavnSearcher,
  GeoStednavnSearcher,
  GeoSearch,
  GSearch,
  PlanSystemSearcher,
  IsoChroneSearcher,
  FkgSearcher,
  FkgInfoProvider,
  DokLinksForLokalplan,
  LifaLinkForAdresseMatrikelHandler,
  LifaLinks,
  EjdExplorerLinks,
  FilarkivLinkForAdresseMatrikelHandler,
  JordForureningsattestLinkHandler,
  CvrLinkHandler,
  DagiInfoProvider,
  CvrInfoProvider,
  RegnskaberForCvrNummerHandler,
  JordStykkeInfoProvider,
  PlanInfoProvider,
  HusnummerInfoProvider,
  OffentligeLinksProvider,
  OffentligeLinksForAdresseMatrikel,
  TjekDitNetForAdresseProvider,
  InfoForKommuneProvider,
  InfoForOpstillingsKredsProvider,
  InfoForPolitidistriktProvider,
  InfoForPostdistriktProvider,
  InfoForRetskredsProvider,
  InfoForSognProvider,
  CvrVirksomhederForAdresseProvider,
  AdresserForHusnummerProvider,
  HusnumreForVejProvider,
  HusnummerForAdresseProvider,
  InfoForGeoStednavnProvider,
  SkraafotoProvider,
  VidiLinkForGeometry,
  VirksomhederMedBranchekodeRadiusforAdresse,
  CowiGadefotoProvider
}

for (let [key, value] of Object.entries(typesToAdd))
  dkTypes.addClass(value, key)

export default dkTypes