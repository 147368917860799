import proj4 from 'proj4/dist/proj4-src.js'

// Checks if `list` looks like a `[x, y]`.
function isXY(list) {
  return list.length >= 2 &&
    typeof list[0] === 'number' &&
    typeof list[1] === 'number'
}

function traverseCoords(coordinates, callback) {
  if (isXY(coordinates)) return callback(coordinates)
  return coordinates.map(function(coord) {
    return traverseCoords(coord, callback)
  })
}

// Simplistic shallow clone that will work for a normal GeoJSON object.
function clone(obj) {
  if (null == obj || 'object' !== typeof obj) return obj
  var copy = obj.constructor()
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr]
  }
  return copy
}

function traverseGeoJson(geometryCb, nodeCb, geojson) {
  if (geojson == null) return geojson

  var r = clone(geojson)
  var self = traverseGeoJson.bind(this, geometryCb, nodeCb)

  switch (geojson.type) {
    case 'Feature':
      r.geometry = self(geojson.geometry)
      break
    case 'FeatureCollection':
      r.features = r.features.map(self)
      break
    case 'GeometryCollection':
      r.geometries = r.geometries.map(self)
      break
    default:
      geometryCb(r)
      break
  }

  if (nodeCb) nodeCb(r)

  return r
}

export function detectProj(geojson, projs) {
  projs = projs || crss
  let incomingCRS = geojson.crs
  let proj
  let epsgCode

  if (incomingCRS === undefined) {
    proj = crss["epsg:4326"]
    epsgCode = "4326"
    return {epsgCode, proj}
  } else {
    if (incomingCRS.type === 'name') {
      proj = projs[incomingCRS.properties.name] || projs[incomingCRS.properties.name.toLowerCase()]
      epsgCode = incomingCRS.properties.name.split(":")[1]
      return {epsgCode, proj}
    } else if (incomingCRS.type === 'EPSG') {
      proj = projs['EPSG:' + incomingCRS.properties.code] || projs['epsg:' + incomingCRS.properties.code]
      epsgCode = incomingCRS.properties.code
      return {epsgCode, proj}
    }
    if (!proj) {
      throw new Error('CRS defined in crs section could not be identified: ' + JSON.stringify(incomingCRS))
    }
  }
}

function getProjFromCRS(crs, projs) {
  let foundCrs
  if (typeof crs === 'string' || crs instanceof String) {
    foundCrs = projs[crs] || projs[crs.toLowerCase()] || proj4.Proj(crs)
  }

  if (!foundCrs) {
    throw new Error('CRS could not be found: ' + crs)
  }

  return foundCrs
}

function calcBbox(geojson) {
  var min = [Number.MAX_VALUE, Number.MAX_VALUE],
    max = [-Number.MAX_VALUE, -Number.MAX_VALUE]
  traverseGeoJson(function(_gj) {
    traverseCoords(_gj.coordinates, function(xy) {
      min[0] = Math.min(min[0], xy[0])
      min[1] = Math.min(min[1], xy[1])
      max[0] = Math.max(max[0], xy[0])
      max[1] = Math.max(max[1], xy[1])
    })
  }, null, geojson)
  return [min[0], min[1], max[0], max[1]]
}

let crss = {
  "epsg:4326": "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs",
  "epsg:25832": "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
  "epsg:3007": "+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
  "epsg:3857": "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs"
}

export function registerCrs(name, def) {
  crss[name.toLowerCase()] = def
}
export function reproject(geojson, forceFromCode, toCode, projs) {
  projs = projs || crss
  let fromProj, toProj
  if (!forceFromCode) {
    fromProj = detectProj(geojson, projs).proj
  } else {
    fromProj = getProjFromCRS(forceFromCode, projs)
  }

  toProj = getProjFromCRS(toCode, projs)
  
  var transformFunc = proj4(fromProj, toProj).forward.bind(transformFunc)

  function transform(coords) {
    let transformed = transformFunc(coords)
    if (coords.length === 3 && coords[2] !== undefined && transformed[2] === undefined) {
      // If the projection doesn't explicitly handle Z coordinate, retain the old one.
      transformed[2] = coords[2]
    }
    return transformed
  }

  let transformGeometryCoords = function(gj) {
    // No easy way to put correct CRS info into the GeoJSON,
    // and definitely wrong to keep the old, so delete it.
    if (gj.crs) {
      delete gj.crs
    }
    gj.coordinates = traverseCoords(gj.coordinates, transform)
  }

  let transformBbox = function(gj) {
    if (gj.bbox) {
      gj.bbox = calcBbox(gj)
    }
  }

  let result = traverseGeoJson(transformGeometryCoords, transformBbox, geojson)
  result.crs = {
    "type": "name",
    "properties": {
      "name": `${toCode}`
    }
  }
  return result
}

export function reverse(geojson) {
  return traverseGeoJson(function(gj) {
    gj.coordinates = traverseCoords(gj.coordinates, function(xy) {
      return [ xy[1], xy[0] ]
    })
  }, null, geojson)
}

export function toWgs84(geojson, from, projs) {
  return reproject(geojson, from, proj4.WGS84, projs)
}