/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
import {getWKTParser} from "../../util/getWKTParser.js"
import DataApiFetcher from "../data-api/Fetcher.js"

/**
 * Viser link til relevante offentlige hjemmesider for en geometri (for et punkt på flade/linie)
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.DawaSearcher
 * _options:
 *   kommunekode: '101'
 *   allowDetails: true
 * detailhandlers:
 *   - _type: Septima.Search.OffentligeLinksProvider
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * dawaSearcher.addDetailHandlerDef(new Septima.Search.OffentligeLinksForAdresseMatrikel({more: true}))
 *
 * @example <caption>ES6:</caption>
 * import OffentligeLinksForAdresseMatrikel from './searchers/detailhandlers/OffentligeLinksForAdresseMatrikel.js'
 * dawaSearcher.addDetailHandlerDef(new OffentligeLinksForAdresseMatrikel({more: false}))
 * @api
 **/
export default class OffentligeLinksProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.links=["jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois", "skraafoto_dataforsyningen"]] Hvilke links skal vises.
   * Muligheder: "jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois", "geokoder", "retbbr", "skraafoto_dataforsyningen", "sdfekort", "plankort"
   **/
  constructor(options= {}) {
    if (!options.buttonText)
      options.buttonText = "Offentlige links"

    super(options)
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler

    //Set links to default
    this.links = ["jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois", "skraafoto_dataforsyningen"]
    if (typeof options.links !== 'undefined') {
      if (options.links.length > 0 && options.links[0] === "*")
        //set links to practically all 
        this.links = ["jfa","bbrmeddelelse","bbrejendom","boligejer.dk","vurdering","bbkort","ois", "geokoder", "retbbr", "skraafoto_dataforsyningen", "sdfekort", "plankort"]
      else
        //Set to options
        this.links = options.links
    }

    this.wktParser = getWKTParser()
    
    this.dataApiNameFor = {
      "boligejer.dk": "boligejer.dk",
      "vurdering":  "vurdering.skat.dk",
      "bbrmeddelelse": "bbrmeddelelse",
      "bbrejendom": "bbrejendom",
      "bbkort": "kort.bbr.dk",
      "retbbr": "ret.bbr.dk",
      "ois": "nytois.dk",
      "skraafoto": "skraafoto.kortforsyningen.dk",
      "skraafoto_dataforsyningen": "skraafoto_dataforsyningen",
      "sdfekort": "sdfekort.dk",
      "plankort": "kort.plandata.dk",
      "jfa": "jordrapport.miljoeportal.dk",
      "arealinformation": "danmarksarealinformation.miljoeportal.dk"
    }
  }

  isApplicable(result) {
    return (typeof result.geometry !== 'undefined')
  }

  async myHandler(result) {
    let pointGeom = this.wktParser.getInteriorPoint(result.geometry)
    return await this.createLinks(result, null, null, pointGeom, null, null, null)
  }

  async createLinks(result, komnr, esrnr, pointGeom, elavskode, matrnr, bfenr, jordstykkeid) {
    let items = []
    let dataApiFetcher = new DataApiFetcher({test: true})
    let options = {}
    if (pointGeom)
      options.labelpointgeom = pointGeom
    if (result) {
      if (result.geometry) {
        options.geometry = result.geometry
        if (result.geometry.type.toLowerCase() === "point") {
          if (!pointGeom)
            options.labelpointgeom = result.geometry
        } else {
          if (!pointGeom)
            options.labelpointgeom = this.wktParser.getInteriorPoint(result.geometry)
        }
      }
      if (options.labelpointgeom)
        options.label = result.title + (result.description ? ("-" + result.description) : "")
    }
    if (komnr)
      options.komnr = komnr
    if (esrnr)
      options.esrnr = esrnr
    if (elavskode && matrnr) {
      options.elavskode = elavskode
      options.matrnr = matrnr
    }
    if (bfenr)
      options.bfenr = bfenr

    if (jordstykkeid)
      options.jordstykkeid = jordstykkeid

    let linksArray = await dataApiFetcher.rpc("createlinkitems", options)
    let linksObject = {}
    linksArray.forEach(link=>{
      linksObject[link.name] = link 
    })
    
    for (let link of this.links) {
      let dataApiName = this.dataApiNameFor[link]
      if (dataApiName) {
        let linkObject = linksObject[dataApiName]
        if (linkObject) {
          items.push({type: 'link',
            name: linkObject.name,
            icon: icons.details.link,
            link: linkObject.link,
            linkTitle: linkObject.linktitle})
        }
      }
    }
    return items
  }

}
